import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
// import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
// import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
// import DateFnsUtils from "@date-io/date-fns";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select } from "@material-ui/core";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ToretaApi from "../../ToretaApi";
// import countries from "../../common/Const/countries";
import { DateDebitruleUpdateBody } from "../../common/interface";
type Props = {
    open: boolean;
    restaurantId: number;
    data: object | null;
    rules: any[] | null;
    close: () => void;
    search: () => void;
};

const defaultValue: DateDebitruleUpdateBody = {
    date: "",
    ruleId: 0,
    oldRuleId: null,
};
const FormDialog: React.FunctionComponent<Props> = ({ open, data, rules, restaurantId, close, search }) => {
    const { t } = useTranslation();
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const [form, setForm] = React.useState<DateDebitruleUpdateBody>(defaultValue);

    useEffect(() => {
        if (data !== null) {
            setForm({ ...defaultValue, ...data });
        }
    }, [data]);

    const updateData = () => {
        const successAction = () => {
            search();
            close();
        };
        const sendform = { ...form };
        toretaApi.updateMonthDebitRule(restaurantId, sendform, successAction);
    };

    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("MANAGER.ACTION.EDIT")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>

                    <Box p={1}>
                        <TextField label={t("MANAGER.DEBITRULE.DATE")} type="text" disabled={true} value={form.date} fullWidth />
                    </Box>
                    <Box p={1}>
                        <Select
                            value={form.ruleId}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                setForm({ ...form, ruleId: event.target.value as number });
                            }}
                        >
                            <MenuItem value={0}>None</MenuItem>
                            {rules?.map((a) => {
                                return <MenuItem value={a.id}>{a.name}</MenuItem>;
                            })}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                    {isManageLevel ? (
                        <Button onClick={updateData} color="primary">
                            {t("MANAGER.ACTION.OK")}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FormDialog;
