import DEF from "../def";

const initialState = localStorage.getItem("sidemenu") ? localStorage.getItem("sidemenu") : true;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.TOGGLE_SIDEMENU:

            return !state;

        default:
            return state;
    }
}