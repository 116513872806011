import React from "react";
import { Provider } from "react-redux";
//import { IntlProvider } from 'react-intl';
import { store } from "./Actions";
/**
 * REDUX注入層
 * @param {} props
 */
const ProviderLevel = (props) => {
    // console.debug("InitState", store.getState());
    //console.log(props.children);
    return <Provider store={store}>{props.children}</Provider>;
};
export default ProviderLevel;
