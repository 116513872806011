import React from "react";
import clsx from "clsx";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { CssBaseline, Container, AppBar, Toolbar, Typography, MenuItem, Menu, IconButton, Link } from "@material-ui/core";
import EarthIcon from "../Icons/Earth";
import MenuIcon from "@material-ui/icons/Menu";
// import CheckIcon from "@material-ui/icons/Check";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import LangList from "../common/Const/LangList";
import LoadingBackdrop from "./LoadingBackdrop";
import ContainerView from "./ContainerView";
import { updteLang, toggleSideMenu } from "../Actions/Actions";
import moment from "moment";
import "moment/locale/ja";
// import "moment/locale/en-us";
import "moment/locale/vi";
import "moment/locale/zh-tw";
import { ManageUser } from "../common/interface";
const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    footerroot: {
        justifyContent: "center",
    },
    footer: {
        "& > * + *": {
            marginLeft: theme.spacing(2),
        },
    },
    link: {
        color: "white",
    },
    container: {
        minHeight: "calc( 100vh - 148px )",
        padding: "0px",
    },
    appBar: {
        position: "relative",
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: "rgb(138 206 54 / 1)",
    },
    footerAppBar: {
        position: "fixed",
        top: "auto",
        bottom: 0,
        backgroundColor: "rgb(138 206 54 / 1)",
    },
}));
interface Map {
    [key: string]: any;
    [index: number]: any;
}
const footer={
    jp_ja: {
        privacy: "https://corp.toreta.in/privacy/",
        terms: "https://toreta.in/terms/toreta-daicho/",
        poweredBy: "https://toreta.in/",
     },
     jp_all: { 
        privacy: "https://corp.toreta.in/privacy/",
        terms: "https://toreta.in/terms/toreta-daicho/",
        poweredBy: "https://toreta.in/",
     },
     default: {
        privacy: "http://toretaasia.com/privacy-policy/",
        terms: "http://toretaasia.com/terms-of-service-2/",
        poweredBy: "http://toretaasia.com",
     },
};

type Props = {};

const Layout: React.FunctionComponent<Props> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const user: ManageUser = useSelector((state: any) => state.user);
    
    const { i18n } = useTranslation();
    const [getFooter,setFooter]=React.useState(footer.default);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    // const currentLanguage = appContext?.lang;
    const handleLangChange = (value: string) => {
        updteLang(value);
        handleClose();
    };
    const setFooterMenu=()=>{
        if(user.company_country=="jp"){
            if(currentLanguage=="ja"){
                setFooter(footer.jp_ja);
            }
            else{
                setFooter(footer.jp_all);
            }
        }else{
            setFooter(footer.default);
        }
    }
    React.useEffect(() => {
        i18n.changeLanguage(currentLanguage);
        moment.locale(currentLanguage);
        setFooterMenu();
        // console.log(currentLanguage)
    }, [currentLanguage, i18n]);

    return (
        <>
            <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale={currentLanguage}>
                <CssBaseline />
                <LoadingBackdrop />
                <AppBar position="static" color="primary" className={classes.appBar}>
                    <Toolbar>
                        {user.id ? (
                            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleSideMenu}>
                                <MenuIcon />
                            </IconButton>
                        ) : (
                            <></>
                        )}
                        <Typography variant="h6" className={classes.title}>
                            Toreta Manager Deposit
                        </Typography>
                        <div>
                            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleMenu} color="inherit">
                                <EarthIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "right",
                                }}
                                open={open}
                                onClose={handleClose}
                            >
                                {LangList.map((lang) => (
                                    <MenuItem key={`${lang.value}-menu`} onClick={() => handleLangChange(lang.value)}>
                                        {currentLanguage === lang.value ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}　{lang.name}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>
                <main>
                    <Container className={classes.container} maxWidth={false}>
                        {props.children || ""}
                    </Container>
                </main>
                <AppBar position="static" className={clsx(classes.appBar, classes.footerAppBar)}>
                    <Toolbar className={classes.footerroot}>
                        <Typography className={classes.footer}>
                            <Link
                                rel="noopener"
                                target="_blank"
                                href={getFooter.privacy}
                                className={classes.link}
                                // onClick={preventDefault}
                            >
                                {t("PRIVACY_POLICY")}
                            </Link>
                            <Link
                                rel="noopener"
                                target="_blank"
                                href={getFooter.terms}
                                className={classes.link}
                                // onClick={preventDefault}
                            >
                                {t("TERMS_OF_SERVICE")}
                            </Link>
                            <Link
                                rel="noopener"
                                target="_blank"
                                href={getFooter.poweredBy}
                                className={classes.link}
                                // onClick={preventDefault}
                            >
                                powered by <span className="icon-logo"></span>
                            </Link>
                        </Typography>
                    </Toolbar>
                </AppBar>
            </MuiPickersUtilsProvider>
        </>
    );
};
export default Layout;
export { ContainerView };
