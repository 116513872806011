import { useEffect } from "react";
import * as React from 'react';
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Input, Select, Chip, FormControlLabel, Switch, FormControl, InputLabel, Grid } from "@material-ui/core";
import { CompactPicker } from "react-color";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { createStyles, makeStyles, withStyles, useTheme, Theme } from "@material-ui/core/styles";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ToretaApi from "../../ToretaApi";
import AutocompleteField from "../../Components/AutocompleteField";
import currency from "../../common/Const/currency.json";
import countries from "../../common/Const/countries";
import { TabPanel } from "../../Components";
import { TimeSpanPicker } from "../../Components";
import { StripeRestaurant, Course, ShopDetail } from "../../common/interface";
import { ImageUploadButton } from "../../Components";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import currencyMap from "../../common/Const/currencyMap.json";
import { useSelector } from "react-redux";
import CourseTable from "./CourseTable";
import { useCourseContext } from './CourseContext';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        }, 
    })
);

const StyledTableCell = withStyles((theme: Theme) => ({
    root:{
        border: "none",
        padding: "8px"
    }
}))(TableCell);

const StyledToggleButtonGroup = withStyles((theme: Theme) => ({
    grouped: {
        color: 'white',
        backgroundColor: '#3f51b5a3',
        "&.MuiToggleButton-root:hover": {
            color: "white",
            backgroundColor: '#3f51b5a3'
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            color: "white",
            backgroundColor: '#3f51b5'
        },
    },
}))(ToggleButtonGroup);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(restaurant: Course, restaurantsIds: string[], theme: Theme) {
    return {
        fontWeight: restaurantsIds.find((a) => a === restaurant.id) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}


type Props = {
    open: boolean;
    data: StripeRestaurant | null;
    close: () => void;
    search: () => void;
};
interface DialogStripeRestaurant extends StripeRestaurant {
    restaurantLink?:string | "";
    file?: File | null;
}
const defaultga = () => {
    return { type: "GTM", code: "" };
};
const JAPAN_COMPANY_CODE:string='jp';
const defaultValue: DialogStripeRestaurant = {
    publicKey: "",
    restaurantLink: "",
    PK: "",
    SK: "",
    ga: defaultga(),
    email: "",
    enabled: true,
    currency: "",
    country: "",
    color: "",
    needTable: true,
    tableInfo: "",
    address: "",
    cancellationPolicy: "",
    needCourse: true,
    courseInfo: "",
    openinghours: [
        { weekday: "0", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "1", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "2", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "3", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "4", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "5", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
        { weekday: "6", lunch_start: 0, lunch_end: 0 , dinner_end:0, dinner_start:0},
    ],
    name: "",
    file: null,
    picture: "",
    onCourse: [],
    courses:[],
    isMultiMealCourse: false,
    isMultiNumberOfPerson: false
    // homepage: "",
};

const FormDialog: React.FunctionComponent<Props> = ({ open, data, close, search }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const weeks = [ 'SUN','MON','TUE', 'WED', 'THU' , 'FRI' , 'SAT' ];
    const user: any = useSelector((state: any) => state.user);
    const { settingCourselist,updateCourselist } = useCourseContext();
    //console.log(courseSettingList); 
    const [selectedJapanCountry,SetJapanCountry] = React.useState<any>(false);
    const currencyList = React.useMemo(() => {
        
        const useCurrency: any = currency;
        const result = [];
        for (let key in useCurrency) {
            const obj = useCurrency[key];
            result.push({
                value: obj.code,
                label: `${obj.symbol} / ${obj.name} / ${obj.code}`,
            });
        }
        return result;
        // eslint-disable-next-line
    }, [currency]);
    const currencyMapList = () => {
        const useCurrency: any =  currency;
        const useCurrencyMap: any = currencyMap;
        const result = [];
        for (let key in useCurrencyMap) {
            const currencyCodes = useCurrencyMap[key];
            for (let index = 0; index < currencyCodes.length; index++) {
                const currencyCode = currencyCodes[index];
                const currencyInfo=useCurrency[currencyCode];
                result.push({
                    countryCode:key,
                    value: currencyCode,
                    label: `${currencyInfo.symbol} / ${currencyInfo.name} / ${currencyInfo.code}`,
                });

            } 
        }
        
        var lst= result.filter((x)=>{return x.countryCode==form.country;});
        if(lst.length>0){
            var currVal= lst.filter((x)=>{return x.value==form.currency;});
            if(currVal.length>0){
                form.currency=currVal[0].value;
            }else{
                form.currency=lst[0].value;
            }
        }
        return lst;
        // eslint-disable-next-line
    };
    //const shop: ShopDetail = useSelector((state: any) => state.shop);
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const { isAdmin } = toretaApi;
    const theme = useTheme();
    const [form, setForm] = React.useState<DialogStripeRestaurant>(defaultValue);
    const [tabIndex, setTabIndex] = React.useState(0);
    const [courseList, setCourseList] = React.useState<Course[]>([]);
    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabIndex(newValue);
    };
    const handleCourseChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setForm({ ...form, onCourse: event.target.value as string[] });
    };
    
    useEffect(() => {       
        //console.log(courseList);
        if (data !== null) {
            //console.log("data",data);
            toretaApi.GetShopOther(data.publicKey);
            toretaApi.getShopFullCourses(data.publicKey).then((coursedatas) => {
                toretaApi.getRestaurantLink(data.publicKey).then( (restaurantLink)=> {                    
                    const newform = { ...defaultValue, ...data, 
                        onCourse: data.onCourse ? data.onCourse : [], file: null , 
                        restaurantLink: restaurantLink };
                    if (!newform.ga) {
                        newform.ga = defaultga();
                    }
                    setForm(newform);
                    if(coursedatas==undefined)coursedatas=[];
                    setCourseList(coursedatas);
                });
            });
            //console.log('getShopSettingCourses');
            toretaApi.getShopSettingCourses(data.publicKey,(data.id==null?0:data.id)).then( (courses)=> {                    
                updateCourselist(courses);
            });
        }
        SetJapanCountry((user.admin? user.select_country==JAPAN_COMPANY_CODE: user.company_country==JAPAN_COMPANY_CODE));
        // eslint-disable-next-line
    }, [data]);
    
    const updateData = () => {
        //TODO: ValidateEmail
        const IsBlank = (value: string) => {
            return !value || value === undefined || value === "";
        };
        if (IsBlank(form.SK)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-001"),
            });
            return;
        } else if (IsBlank(form.PK)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-002"),
            });
            return;
        } else if (IsBlank(form.currency)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-003"),
            });
            return;
        } else if (IsBlank(form.country)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-004"),
            });
            return;
        } else {
            const successAction = () => {
                search();
                close();
            };
            const sendform = { ...form };
            delete sendform["file"];
            sendform.courses = settingCourselist;  
            toretaApi.updateRestaurantStripe(sendform, form.file ?? null, successAction);
        }
    };
    const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            setForm({ ...form, file: event.target.files[0] });
        }
    };
    const clickOpenButton = () => {
        
        window.open(form.restaurantLink);
        
    };

  const handleNeedCourseChange = (
    event: React.MouseEvent<HTMLElement>,
    newOption: boolean | null,
  ) => {
      setForm({ ...form, needCourse: newOption });
  };

    return ( 
        <Box>
            <Dialog open={open} className="paymentDialogWidth" fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {`${form.name} `}
                    {/* {isManageLevel ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.READONLY")} */}
                </DialogTitle>
                <DialogContent>
                    <AppBar position="static" color="default">
                        <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
                            <Tab label={t("MANAGER.TAB.RESTAURANTINFO")} id={`full-width-tab-0`} aria-controls={`full-width-tabpanel-0`} />
                            <Tab label={t("MANAGER.TAB.COURSE")} id={`full-width-tab-3`} aria-controls={`full-width-tabpanel-0`} />
                            <Tab label={t("MANAGER.TAB.PAYMENT")} id={`full-width-tab-1`} aria-controls={`full-width-tabpanel-0`} />
                            <Tab label={t("MANAGER.TAB.OPENINGHOURS")} id={`full-width-tab-2`} aria-controls={`full-width-tabpanel-0`} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                        <Box p={1}>
                                <Button variant="contained" color="primary" onClick={clickOpenButton}>
                                {t("MANAGER.ACTION.OPEN_RESTAURANT")}
                                </Button>
                        </Box>
                        <Box p={1}>
                            {t("MANAGER.FIELD.APP_BAR_COLOR")}
                            <p />
                            <CompactPicker
                                color={form.color}
                                onChangeComplete={(value: any) => {
                                    setForm({ ...form, color: value.hex });
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                label={t("MANAGER.FIELD.PIC")}
                                type="text"
                                value={form.picture}
                                fullWidth
                                onChange={(event) => {
                                    setForm({ ...form, picture: event.target.value });
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <ImageUploadButton fileChange={fileChange} file={form.file ?? null} />
                        </Box>
                        <Box p={1}>
                            <AutocompleteField
                                label={t("MANAGER.FIELD.COUNTRY")}
                                variant={"standard"}
                                optionList={countries}
                                value={form.country}
                                onChange={(value: any) => {
                                    setForm({ ...form, country: value });
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                // label={t("MANAGER.FIELD.TABLE_INFO")}
                                label={t("ADDRESS")}
                                type="text"
                                multiline
                                placeholder="Enter Address"
                                variant="standard"
                                value={form.address}
                                fullWidth
                                inputProps={{ maxLength: 255}}
                                onChange={(event) => {
                                    setForm({ ...form, address: event.target.value });
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <FormControl fullWidth>
                                <InputLabel>{t("COURSE")}</InputLabel>
                                <Select
                                    label={t("COURSE")}
                                    multiple
                                    fullWidth
                                    value={form.onCourse}
                                    onChange={handleCourseChange}
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {(selected as string[]).map((value) => {
                                                const label = courseList.find((a) => a.id === value)?.name;
                                                return <Chip key={value} label={label} className={classes.chip} />;
                                            })}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {courseList.map((course) => (
                                        <MenuItem key={course.id} value={course.id} style={getStyles(course, form.onCourse || [], theme)}>
                                            {course.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box p={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.needTable}
                                        color="primary"
                                        onChange={(event) => {
                                            setForm({ ...form, needTable: event.target.checked });
                                        }}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                }
                                label={t("MANAGER.FIELD.NEED_TABLE")}
                                labelPlacement="start"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                label={t("MANAGER.FIELD.TABLE_INFO")}
                                type="text"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={form.tableInfo}
                                fullWidth
                                onChange={(event) => {
                                    setForm({ ...form, tableInfo: event.target.value });
                                }}
                            />
                        </Box>
                        <Box p={1}>
                            <FormControlLabel
                                control={
                                    <StyledToggleButtonGroup
                                        size="small"
                                        value={form.needCourse}
                                        exclusive
                                        onChange={handleNeedCourseChange}
                                        >
                                        <ToggleButton value={false}>OFF</ToggleButton>
                                        <ToggleButton value={true}>ON</ToggleButton>
                                        <ToggleButton value={null}>OPTION</ToggleButton>
                                        </StyledToggleButtonGroup>
                                }
                                label={t("MANAGER.FIELD.NEED_COURSE") + "　"}
                                labelPlacement="start"
                                
                            />
                        </Box>
                        <Box p={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.isMultiMealCourse}
                                        color="primary"
                                        onChange={(event) => {
                                            setForm({ ...form, isMultiMealCourse: event.target.checked });
                                        }}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                }
                                label={t("MANAGER.FIELD.MULTI_MEAL_COURSE")}
                                labelPlacement="start"
                            />
                        </Box>
                        <Box p={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.isMultiNumberOfPerson}
                                        color="primary"
                                        onChange={(event) => {
                                            setForm({ ...form, isMultiNumberOfPerson: event.target.checked });
                                        }}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                }
                                label={t("MANAGER.FIELD.NULTI_NUMBER_OF_PERSON")}
                                labelPlacement="start"
                            />
                        </Box>
                        <Box p={1}>
                            <TextField
                                label={t("MANAGER.FIELD.COURSE_INFO")}
                                type="text"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={form.courseInfo}
                                fullWidth
                                onChange={(event) => {
                                    setForm({ ...form, courseInfo: event.target.value });
                                }}
                            />
                        </Box>
                        {(selectedJapanCountry ==true)?(<Box p={1}>
                            <TextField
                                label={t("CANCELLATION_POLICY")}
                                type="text"
                                multiline
                                rows={4}
                                placeholder="Cancellation policy"
                                variant="outlined"
                                value={form.cancellationPolicy}
                                fullWidth
                                inputProps={{ maxLength: 10000}}
                                onChange={(event) => {
                                    setForm({ ...form, cancellationPolicy: event.target.value });
                                }}
                            />
                        </Box>):(<></>)}
                        <Box p={1}>
                            <Grid container>
                                <Grid xs={4} item>
                                    <FormControl fullWidth>
                                        <InputLabel>{t("MANAGER.FIELD.GA_TYPE")}</InputLabel>
                                        <Select
                                            label={t("MANAGER.FIELD.GA_TYPE")}
                                            fullWidth
                                            value={form.ga?.type}
                                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                                const ga = {
                                                    code: "",
                                                    ...form.ga,
                                                    type: event.target.value as string,
                                                };
                                                setForm({ ...form, ga });
                                            }}
                                            input={<Input />}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem key={"GTM"} value={"GTM"}>
                                                GTM
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label={t("MANAGER.FIELD.GA_CODE")}
                                        type="text"
                                        value={form.ga?.code}
                                        fullWidth
                                        onChange={(event) => {
                                            const ga = {
                                                type: "GTM",
                                                ...form.ga,
                                                code: event.target.value,
                                            };
                                            setForm({ ...form, ga });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <TextField
                                        label={t("MANAGER.FIELD.SHOP_EMAIL")}
                                        type="text"
                                        value={form.email}
                                        fullWidth
                                        onChange={(event) => {
                                            setForm({ ...form, email: event.target.value });
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        {/* <Box p={1}>
                            <TextField
                                label={t("MANAGER.FIELD.HOMEPAGE")}
                                type="text"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={form.homepage ? form.homepage : shop.homepage}
                                fullWidth
                                onChange={(event) => {
                                    setForm({ ...form, homepage: event.target.value });
                                }}
                            />
                        </Box> */}
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1}>
                        <Box className="course-list" >
                        <CourseTable data={data} />
                        </Box>
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2} dir={theme.direction}>
                        <Box p={1}>
                            <AutocompleteField
                                label={t("MANAGER.FIELD.CURRENCY")}
                                variant={"standard"}
                                optionList={currencyMapList()}
                                value={form.currency}
                                onChange={(value: any) => {
                                    setForm({ ...form, currency: value });
                                }}
                            />
                        </Box>
                        {isManageLevel ? (
                            <>
                                <Box p={1}>
                                    <TextField
                                        label={t("MANAGER.FIELD.STRIPE_SK")}
                                        type="text"
                                        value={form.SK}
                                        fullWidth
                                        onChange={(event) => {
                                            setForm({ ...form, SK: event.target.value });
                                        }}
                                    />
                                </Box>
                                <Box p={1}>
                                    <TextField
                                        label={t("MANAGER.FIELD.STRIPE_PK")}
                                        type="text"
                                        value={form.PK}
                                        fullWidth
                                        onChange={(event) => {
                                            setForm({ ...form, PK: event.target.value });
                                        }}
                                    />
                                </Box>
                            </>
                        ) : null}
                        {isAdmin ? (
                            <>
                        <Box p={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.enabled}
                                        color="primary"
                                        onChange={(event) => {
                                            setForm({ ...form, enabled: event.target.checked });
                                        }}
                                        inputProps={{ "aria-label": "secondary checkbox" }}
                                    />
                                }
                                label={t("MANAGER.STATUS.ENABLED")}
                            />
                        </Box>
                            </>
                        ) : null}
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3} dir={theme.direction}>
                        <Box p={1}>
                            <TableContainer>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableBody>
                                    {weeks.map((week: any , weekIdx: number) => {
                                        return (
                                        <TableRow key={weekIdx}>
                                            <StyledTableCell>{t(week)}</StyledTableCell>
                                            <StyledTableCell>
                                            <TimeSpanPicker
                                            start_time={form.openinghours[weekIdx].lunch_start} 
                                            end_time={form.openinghours[weekIdx].lunch_end} 
                                            onStartTimeChange={(pickedTime: number)=>{
                                                form.openinghours[weekIdx].lunch_start = pickedTime;
                                                setForm({ ...form});
                                            }}
                                            onEndTimeChange={(pickedTime: number)=>{ 
                                                form.openinghours[weekIdx].lunch_end = pickedTime;
                                                setForm({ ...form});
                                            }} />
                                            </StyledTableCell>
                                            <StyledTableCell>|</StyledTableCell>
                                            <StyledTableCell>
                                            <TimeSpanPicker 
                                            start_time={form.openinghours[weekIdx].dinner_start} 
                                            end_time={form.openinghours[weekIdx].dinner_end} 
                                            onStartTimeChange={(pickedTime: number)=>{
                                                form.openinghours[weekIdx].dinner_start = pickedTime;
                                                setForm({ ...form});
                                            }}
                                            onEndTimeChange={(pickedTime: number)=>{ 
                                                form.openinghours[weekIdx].dinner_end = pickedTime;
                                                setForm({ ...form});
                                            }} />
                                            </StyledTableCell>
                                        </TableRow>
                                        );
                                    })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </TabPanel>
                </DialogContent>
                <DialogActions className={"tab-"+tabIndex}>
                    {tabIndex==1?(
                    <Box className="course-list-note">
                        <div>
                        {t('MANAGER.COURSE.NOTE')}
                        </div>
                        </Box>
                    ):(<></>)
                    }
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                    {isManageLevel ? (
                        <Button onClick={updateData} color="primary">
                             {tabIndex==1?t("MANAGER.ACTION.SAVE"):t("MANAGER.ACTION.OK")}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default FormDialog;
