import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import AutocompleteField from "../../Components/AutocompleteField";
import { Button, TextField } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../ToretaApi";
import Dialog from "./Dialog";
import { ManageUser, MenuOption, CompanyUserFilter } from "../../common/interface";
import DialogCSV from "./DialogCSV";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        container: {
            maxHeight: window.innerHeight - 280,
            [theme.breakpoints.down("md")]: {
                maxHeight: window.innerHeight - 280,
            },
        },
        table: {
            minWidth: 650,
        },
    })
);
type Props = {};
interface DialogStatus {
    open: boolean;
    userId: null | number;
    companyId?: number;
}
interface DialogCSVStatus {
    open: boolean;
    userId: null | number;
    publicKey?: string | null;
}
const StickyTable: React.FunctionComponent<Props> = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const user: ManageUser = useSelector((state: any) => state.user);
    const [pageData, setPageData] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [status, setStatus] = React.useState(null);
    const [filter, setFilter] = React.useState<CompanyUserFilter>({
        name: "",
        account: "",
        status: null,
        page: 0,
        perPage: 0,
    });
    const [dialogStatus, setDialogStatus] = React.useState<DialogStatus>({ open: false, userId: null });
    const [dialogCSVStatus, setDialogCSVStatus] = React.useState<DialogCSVStatus>({ open: false, userId: null });
    const [companyList, setCompanyList] = React.useState<MenuOption[] | null>(null);
    useEffect(() => {
        toretaApi.getCompanyList().then((data) => {
            setCompanyList(data);
        });
        // eslint-disable-next-line
    }, []);
    const dialogClose = () => {
        setDialogStatus({ open: false, userId: null });
        setDialogCSVStatus({ open: false, userId: null });
    };  

    const dialogCSVOpen = ()=>{
        setDialogCSVStatus({ open: true, userId: null, publicKey: "" });
    }
    const dialogOpen = (edituser: ManageUser | null) => {
        let useCompanyId = user.companyId;

        if (edituser) {
            if (user.admin) {
                useCompanyId = edituser.companyId;
            }
            setDialogStatus({ open: true, userId: edituser.id || null, companyId: useCompanyId });
        } else {
            if (user.admin) {
                useCompanyId = 0;
            }
            setDialogStatus({ open: true, userId: null, companyId: useCompanyId });
        }
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const toretaApi = ToretaApi();
    const clickButton = () => {
        if (page === 0) {
            search();
        } else {
            setPage(0);
        }
    };
    const exportyCSV=()=>{
        dialogCSVOpen()
    }
    const deleteUser = (userId: number, username: string) => {
        Swal.fire({
            title: t("MANAGER.CONFIRM.DISABLE_USER", { username }),
            showDenyButton: true,
            icon: "warning",
            confirmButtonText: t("MANAGER.STATUS.YES"),
            denyButtonText: t("MANAGER.STATUS.NO"),
        }).then((result: any) => {
            if (result.isConfirmed) {
                toretaApi.deleteUser(userId).then(search);
            }
        });
    };
    const search = () => {
        const sendFilter = {
            ...filter,
            page: page,
            perPage: rowsPerPage,
        };
        toretaApi.getUserList(sendFilter).then(setPageData);
    };

    useEffect(() => {
        search();
        // eslint-disable-next-line
    }, [page, rowsPerPage, filter]);

    const canDo = user.admin || user.companyOwner;
    const isShowExport = user.username ==='hanae@toreta.in';
    return (
        <>
            <DialogCSV open={dialogCSVStatus.open} publicKey={dialogCSVStatus.publicKey} userId={dialogCSVStatus.userId} close={dialogClose} search={search} />
            <Dialog open={dialogStatus.open} companyId={dialogStatus.companyId} userId={dialogStatus.userId} close={dialogClose} search={search} />
            <Grid item xs={3} lg={3}>
                <TextField
                    variant="outlined"
                    value={filter.name}
                    fullWidth
                    label={t("NAME")}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            name: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3} lg={3}>
                <TextField
                    variant="outlined"
                    value={filter.account}
                    fullWidth
                    label={t("MANAGER.LOGIN.ACCOUNT")}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            account: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={3} lg={3}>
                <Button className="user-button-group" variant="contained" color="primary" onClick={clickButton}>
                    {t("MANAGER.ACTION.SEARCH")}
                </Button>
                {canDo ? (
                    <Button className="user-button-group" 
                        variant="contained"
                        color="secondary"
                        disabled={!canDo}
                        onClick={() => {
                            dialogOpen(null);
                        }}
                    >
                        {t("MANAGER.ACTION.ADD")}
                    </Button>
                ) : (
                    <></>
                )}
                {isShowExport?(
                <Button className="user-button-group"  variant="contained" color="primary" onClick={exportyCSV}>
                    {t("MANAGER.ACTION.EXPORT_CSV")}
                </Button>
                ):( <></>)
                }
            </Grid>
            <Grid item xs={3} lg={3}>
                <AutocompleteField
                        label={t("MANAGER.FIELD.STATUS")}
                        optionList={[{value: 0, label: t("MANAGER.STATUS.ENABLED")}, {value: 1, label: t("MANAGER.STATUS.DISABLED")}]}
                        value={status}
                        onChange={(value: any) => {
                            setStatus(value);
                            setFilter({
                                ...filter,
                                status: value,
                            });
                        }}
                />
            </Grid>
            {pageData && pageData.rows ? (
                <Grid item xs={12} lg={12}>
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        {user.admin ? <TableCell>{t("MANAGER.FIELD.COMPANY")}</TableCell> : <></>}
                                        <TableCell>{t("NAME")}</TableCell>
                                        <TableCell>{t("MANAGER.LOGIN.ACCOUNT")}</TableCell>
                                        <TableCell>
                                            {t("MANAGER.FIELD.OWNER")}
                                        </TableCell>
                                        <TableCell>{t("MANAGER.FIELD.STATUS")}</TableCell>
                                        {/* <TableCell>{t("MANAGER.FIELD.UPDATEDAT")}</TableCell> */}
                                        <TableCell>{t("MANAGER.RESERVE.ACTIONS")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData.rows.map((row: ManageUser) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                {user.admin ? <TableCell>{companyList && companyList.find((a) => a.value === row.companyId)?.label}</TableCell> : <></>}
                                                <TableCell style={{ minWidth: 100 }}>{row.name}</TableCell>
                                                <TableCell style={{ minWidth: 80 }}>{row.username}</TableCell>
                                                <TableCell style={{ minWidth: 100 }}>
                                                    {row.companyOwner
                                                        ? t("MANAGER.USER_TYPE.COMPANY_OWNER")
                                                        : row.restaurantOwner
                                                        ? t("MANAGER.USER_TYPE.RESTAURANT_OWNER")
                                                        : t("MANAGER.USER_TYPE.NORMAL")}
                                                </TableCell>
                                                <TableCell style={{ minWidth: 80 }}>{row.disabled ? t("MANAGER.STATUS.DISABLED") : t("MANAGER.STATUS.ENABLED")}</TableCell>
                                                {/* <TableCell>{row.updatedAt}</TableCell> */}
                                                <TableCell  style={{ minWidth: 160 }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            dialogOpen(row);
                                                        }}
                                                    >
                                                        {canDo ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.READONLY")}
                                                    </Button>
                                                    {!row.disabled && canDo ? (
                                                        <Button variant="contained" color="secondary" onClick={() => deleteUser(row.id || -1, row.username)}>
                                                            {t("MANAGER.STATUS.DISABLED")}
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={pageData.count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => {
                                return t("MANAGER.TABLE.CURRENT", {
                                    from,
                                    to,
                                    count,
                                });
                            }}
                            labelRowsPerPage={t("MANAGER.TABLE.PERPAGE")}
                            backIconButtonText={t("MANAGER.TABLE.PER_PAGE")}
                            nextIconButtonText={t("MANAGER.TABLE.NEXT_PAGE")}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default StickyTable;
