import React from "react";
// import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import ListSubheader from "@material-ui/core/ListSubheader";
import { /*useTheme,*/ makeStyles } from "@material-ui/core/styles";
// import { VariableSizeList } from "react-window";
// import InputAdornment from "@material-ui/core/InputAdornment";
// export default ComboBox;
// const LISTBOX_PADDING = 8; // px

// function renderRow(props) {
//     const { data, index, style } = props;
//     return React.cloneElement(data[index], {
//         style: {
//             ...style,
//             top: style.top + LISTBOX_PADDING,
//         },
//     });
// }

// const OuterElementContext = React.createContext({});

// const OuterElementType = React.forwardRef((props, ref) => {
//     const outerProps = React.useContext(OuterElementContext);
//     return <div ref={ref} {...props} {...outerProps} />;
// });

// function useResetCache(data) {
//     const ref = React.useRef(null);
//     React.useEffect(() => {
//         if (ref.current != null) {
//             ref.current.resetAfterIndex(0, true);
//         }
//     }, [data]);
//     return ref;
// }

// Adapter for react-window
// const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
//     const { children, ...other } = props;
//     const itemData = React.Children.toArray(children);
//     const theme = useTheme();
//     const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
//     const itemCount = itemData.length;
//     const itemSize = smUp ? 36 : 48;

//     const getChildSize = (child) => {
//         if (React.isValidElement(child) && child.type === ListSubheader) {
//             return 48;
//         }

//         return itemSize;
//     };

//     const getHeight = () => {
//         if (itemCount > 8) {
//             return 8 * itemSize;
//         }
//         return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
//     };

//     const gridRef = useResetCache(itemCount);

//     return (
//         <div ref={ref}>
//             <OuterElementContext.Provider value={other}>
//                 <VariableSizeList
//                     itemData={itemData}
//                     height={getHeight() + 2 * LISTBOX_PADDING}
//                     width="100%"
//                     ref={gridRef}
//                     outerElementType={OuterElementType}
//                     innerElementType="ul"
//                     itemSize={(index) => getChildSize(itemData[index])}
//                     overscanCount={5}
//                     itemCount={itemCount}
//                 >
//                     {renderRow}
//                 </VariableSizeList>
//             </OuterElementContext.Provider>
//         </div>
//     );
// });

// ListboxComponent.propTypes = {
//     children: PropTypes.node,
// };

const useStyles = makeStyles({
    listbox: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

// const renderGroup = (params) => [
//     <ListSubheader key={params.key} component="div">
//         {params.group}
//     </ListSubheader>,
//     params.children,
// ];

export default function Virtualize({ variant = "outlined", label = "", optionList = [], disabled = false, value, onChange,onOpen=function(){}, errorValue = false, disableClearable=false}) {
    const classes = useStyles();

    // const { t } = useTranslation();

    const selfOnChange = (event, newInputValue) => {
        // console.log(label + 5, newInputValue, typeof newInputValue.value);
        onChange(newInputValue ? newInputValue.value : null);
    };
    const filterOptions = createFilterOptions({
        stringify: (option) => `${option.label}`,
    });
    // React.useEffect(() => {
    //     // console.log(label, value, typeof value,  optionList);
    // }, [value, optionList]);
    if (!optionList) {
        return (
            <>
                <TextField error={errorValue} variant={variant} label={label} fullWidth={true} />
            </>
        );
    }
    return (
        <>
            {/* <div>{`value: ${value !== null ? `'${value}'` : "null"}`}</div> */}

            <Autocomplete
                disableClearable={disableClearable}
                fullWidth={true}
                filterOptions={filterOptions}
                disableListWrap={disabled}
                getOptionLabel={() => {
                    let result = "";
                    if (optionList.length > 0) {
                        const matchOption = optionList.find((a) => a.value === value);
                        if (matchOption) {
                            result = `${matchOption.label}`;
                        }
                    } else {
                        result = `${value.label}`;
                    }
                    // console.log(label + 2, value, optionList, result);
                    return result;
                }}
                getOptionSelected={(option) => {
                    // console.log(label + 3, option, value, option.value === value);
                    return option.value === value;
                }}
                disabled={disabled}
                classes={classes}
                // inputValue={matchOption?matchOption.label:undefined}
                // ListboxComponent={ListboxComponent}
                // renderGroup={renderGroup}
                options={optionList}
                value={value}
                onChange={selfOnChange}
                onOpen={onOpen}
                renderInput={(params) => {
                    return <TextField error={errorValue} variant={variant} label={label} {...params} autoComplete="off" />;
                }}
                renderOption={(item) => {
                    return (
                        <>
                            <span>{item.label}</span>
                        </>
                    );
                }}
            />
        </>
    );
}
