import React from "react";
import basecountry from "../../country.json";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListSubheader from "@material-ui/core/ListSubheader";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { VariableSizeList } from "react-window";
import InputAdornment from "@material-ui/core/InputAdornment";
// export default ComboBox;
const LISTBOX_PADDING = 8; // px

function renderRow(props) {
    const { data, index, style } = props;
    return React.cloneElement(data[index], {
        style: {
            ...style,
            top: style.top + LISTBOX_PADDING,
        },
    });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up("sm"), { noSsr: true });
    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (React.isValidElement(child) && child.type === ListSubheader) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});

// ListboxComponent.propTypes = {
//     children: PropTypes.node,
// };

const useStyles = makeStyles({
    listbox: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
});

const CountryList = basecountry.map((a) => ({ ...a, phone: "+" + a.phone }));

const renderGroup = (params) => [
    <ListSubheader key={params.key} component="div">
        {params.group}
    </ListSubheader>,
    params.children,
];
const filterOptions = createFilterOptions({
    stringify: (option) => `${option.label} ${option.iso} +${option.phone}`,
});
export default function Virtualize({ value, onChange }) {
    const classes = useStyles();
    const matchOption = CountryList.find((a) => a.iso === value);
    const { t } = useTranslation();
    const selfOnChange = (event, newInputValue) => {
        // console.log(newInputValue);
        onChange(newInputValue ? "" + newInputValue.iso : null);
    };
    // console.log(matchOption)
    return (
        <Autocomplete
            fullWidth={true}
            filterOptions={filterOptions}
            disableListWrap
            getOptionLabel={(option) => {
                if (option) {
                    return `${option.phone}`;
                } else {
                    return "";
                }
            }}
            classes={classes}
            ListboxComponent={ListboxComponent}
            renderGroup={renderGroup}
            options={CountryList}
            value={matchOption}
            onChange={selfOnChange}
            // groupBy={(option) => option[0].toUpperCase()}
            // renderTags={(params) => <TextField {...params} variant="" label={t("456OUNTRY")} />}
            renderInput={(params) => {
                // console.log("here", params.inputProps);
                if (matchOption) {
                    // console.log("heretest");
                    const useClass = `flag-icon flag-icon-${matchOption.iso.toLowerCase()}`;
                    params.InputProps.startAdornment = (
                        <>
                            <InputAdornment position="start">
                                <span className={useClass}></span>
                            </InputAdornment>
                            {params.InputProps.startAdornment}
                        </>
                    );
                }

                return <TextField variant="outlined" label={t("PERSONAL_INFO.COUNTRY")} {...params} />;
            }}
            renderOption={(item) => {
                const useClass = `flag-icon flag-icon-${item.iso.toLowerCase()}`;
                return (
                    <>
                        <span className={useClass}></span>　
                        <span>
                            {item.label}({item.iso}) {item.phone}
                        </span>
                    </>
                );
            }}
        />
    );
}
export { CountryList };
