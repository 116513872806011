import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Switch, Input, Select, Chip, FormControl, InputLabel, FormControlLabel } from "@material-ui/core";
import { createStyles, makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import ToretaApi from "../../ToretaApi";
import { ManageUser, MenuOption, ManagerUserBody } from "../../common/interface";
import { CheckBox } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },      
        chip: {
            margin: 2,
        },
        redchip: {
            margin: 2,
            color: "red",
            textDecorationLine: 'line-through'
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(restaurant: MenuOption, restaurantsIds: number[], theme: Theme) {
    return {
        fontWeight: restaurantsIds.find((a) => a === restaurant.value) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

type Props = {
    open: boolean;
    userId: number | null;
    companyId: number | undefined;
    close: () => void;
    search: () => void;
};
const defaultValue: ManagerUserBody = {
    name: "",
    companyOwner: false,
    restaurantOwner: false,
    staffOwner:false,
    disabled: false,
    password: "",
    username: "",
    company_country:"",
    select_country:"",
    restaurants: [],
    isConfirmOTP:false,
    isOTPExpired: false,
    OTPExpired: null
};
const FormDialog: React.FunctionComponent<Props> = ({ open, userId, companyId, close, search }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const user: ManageUser = useSelector((state: any) => state.user);
    const toretaApi = ToretaApi();
    const [restaurantOptions, setRestaurantOptions] = React.useState<MenuOption[]>([]);
    const [form, setForm] = React.useState<ManagerUserBody>(defaultValue);
    const [companyList, setCompanyList] = React.useState<MenuOption[] | null>(null);
    useEffect(() => {
        toretaApi.getCompanyList().then((data) => {
            setCompanyList(data);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userId) {
            toretaApi.getUserInfo(userId).then((data) => {
                setForm({ ...defaultValue, ...data });
            });
        } else {
            setForm({ ...defaultValue, companyId: companyId ? companyId : 0 });
        }
        // eslint-disable-next-line
    }, [userId]);
    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setForm({ ...form, restaurants: event.target.value as number[] });
    };
    const showHelp =() =>{
        let helpText = `${t("MANAGER.LOGIN.SIGNIN")} ${t("MANAGER.STATUS.DISABLED")}`;

        helpText =`${t("MANAGER.USER_TYPE.COMPANY_OWNER")}: ${t("MANAGER.USER_TYPE_HELP.COMPANY_OWNER")}<BR>
        ${t("MANAGER.USER_TYPE.RESTAURANT_OWNER")}: ${t("MANAGER.USER_TYPE_HELP.RESTAURANT_OWNER")}<BR> 
        ${t("MANAGER.USER_TYPE.NORMAL")}: ${t("MANAGER.USER_TYPE_HELP.NORMAL")}`
        // if(form.disabled === false){
        //     if (form.companyOwner) {
        //         helpText = t("MANAGER.USER_TYPE_HELP.COMPANY_OWNER")
        //     }else{
        //         if (form.restaurantOwner) {
        //             helpText = t("MANAGER.USER_TYPE_HELP.RESTAURANT_OWNER")
        //         }
        //         else{
        //             helpText = t("MANAGER.USER_TYPE_HELP.NORMAL")
        //         }
        //     }
        // }
        Swal.fire({
            icon: "info",
            html: helpText,
        });
    }
    const updateData = () => {
        const successAction = () => {
            search();
            close();
        };
        if (!userId) {
            if (form.name === "") {
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.USER-001"),
                });
                return;
            }
            if (form.password === "") {
                Swal.fire({
                    icon: "error",
                    title: t("MANAGER.ERRORMESSAGE.USER-002"),
                });
                return;
            }
        }
        if (user.admin && (form.companyId === null || form.companyId === 0)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.USER-004"),
            });
            return;
        }
        if (!form.companyOwner && form.restaurants.length === 0) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.USER-003"),
            });
            return;
        }
        const sendform = { ...form };
        if (sendform.companyOwner) {
            sendform.restaurants = [];
        }
        toretaApi.updateUserInfo(userId, sendform, successAction);
    };
    const resetPassword = () => {
        const successAction = () => {
            search();
            close();
            Swal.fire({
                icon: "success",
                title: t("MANAGER.MESSAGE.EMAIL_SENT"),
            });
        };
        toretaApi.forceResetUserPassword(form.username, successAction);
    };
    useEffect(() => {
        if (companyId) {
            toretaApi.getRestaurantOptions(companyId).then(setRestaurantOptions);
        }
        // eslint-disable-next-line
    }, [companyId]);
    const canDo = user.admin || user.companyOwner;
    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{!canDo ? t("MANAGER.ACTION.READONLY") : userId ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.ADD")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    {user.admin ? (
                        <>
                            <Box p={1}>
                                <FormControl fullWidth>
                                    <InputLabel>{t("MANAGER.FIELD.COMPANY")}</InputLabel>
                                    <Select
                                        label={t("MANAGER.FIELD.COMPANY")}
                                        fullWidth
                                        value={form.companyId}
                                        onChange={(event) => {
                                            setForm({ ...form, companyId: event.target.value as number, restaurants: [] });
                                            if (user.admin) {
                                                toretaApi.getRestaurantOptions(event.target.value as number).then(setRestaurantOptions);
                                            }
                                        }}
                                    >
                                        {companyList &&
                                            companyList.map((company: MenuOption) => (
                                                <MenuItem key={company.value} value={company.value}>
                                                    {company.label}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </>
                    ) : (
                        <></>
                    )}
                    <Box p={1}>
                        <TextField
                            label={t("NAME")}
                            type="text"
                            value={form.name}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, name: event.target.value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.LOGIN.ACCOUNT")}
                            type="email"
                            value={form.username}
                            inputProps={{
                                autoComplete: "username",
                                form: {
                                    autocomplete: "off",
                                },
                            }}
                            fullWidth
                            disabled={userId ? true : false}
                            onChange={(event) => {
                                setForm({ ...form, username: event.target.value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.LOGIN.PASSWORD")}
                            type="password"
                            value={form.password}
                            inputProps={{
                                autoComplete: "password",
                                form: {
                                    autocomplete: "off",
                                },
                            }}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, password: event.target.value });
                            }}
                        />
                    </Box>
                    {!form.companyOwner ? (
                        <Box p={1}>
                            <FormControl fullWidth>
                                <InputLabel>{t("MANAGER.FIELD.RESTAURANT")}</InputLabel>
                                <Select
                                    label={t("MANAGER.FIELD.RESTAURANT")}
                                    multiple
                                    fullWidth
                                    value={form.restaurants}
                                    onChange={handleChange}
                                    input={<Input />}
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {(selected as number[]).map((value) => {
                                                
                                                const label = restaurantOptions.find( (a) => a.value === value)?.label;
                                                const enabled = restaurantOptions.find( (a) => a.value === value)?.enabled;
                                                //console.log(label,enabled);
                                                if(!enabled){
                                                    return <Chip key={value} label={label} className={classes.redchip} />;
                                                }else{
                                                    return <Chip key={value} label={label} className={classes.chip} />;
                                                }
                                            })}
                                        </div>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {restaurantOptions.map((restaurant) => (
                                        
                                        <MenuItem key={restaurant.value} value={restaurant.value} style={getStyles(restaurant, form.restaurants, theme)}>
                                            {restaurant.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    ) : (
                        <></>
                    )}
                    <Box p={1}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={form.companyOwner}
                                    color="primary"
                                    onChange={(event) => {
                                        setForm({ ...form, companyOwner: event.target.checked,staffOwner:false,restaurantOwner:false});
                                    }}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            }
                            label={t("MANAGER.USER_TYPE.COMPANY_OWNER")}
                        />
                    </Box>
                    <Box p={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={form.restaurantOwner}
                                    color="primary"
                                    onChange={(event) => {
                                        setForm({ ...form, restaurantOwner: event.target.checked,staffOwner:false,companyOwner:false });
                                    }}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            }
                            label={t("MANAGER.USER_TYPE.RESTAURANT_OWNER")}
                        />
                    </Box>
                    <Box p={1}>
                        <FormControlLabel
                            control={
                                <Switch 
                                    checked={form.staffOwner}
                                    color="primary"
                                    onChange={(event) => {
                                        setForm({ ...form, staffOwner: event.target.checked,restaurantOwner:false,companyOwner:false });
                                    }}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            }
                            label={t("MANAGER.USER_TYPE.NORMAL")}
                        />
                    </Box>
                    <Box p={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={form.disabled}
                                    color="secondary"
                                    onChange={(event) => {
                                        setForm({ ...form, disabled: event.target.checked });
                                    }}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                            }
                            label={t("MANAGER.STATUS.DISABLED")}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={resetPassword} color="secondary">
                        {t("MANAGER.ACTION.RESET_PASSWORD")}
                    </Button>
                    <Button onClick={showHelp} color="secondary">
                        {t("MANAGER.ACTION.USER_HELP")}
                    </Button>
                    <Button onClick={close} color="secondary">
                        {canDo ? t("MANAGER.ACTION.CANCEL") : t("MANAGER.ACTION.READONLY")}
                    </Button>
                    {canDo ? (
                        <Button onClick={updateData} color="primary">
                            {t("MANAGER.ACTION.OK")}
                        </Button>
                    ) : (
                        <></>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FormDialog;
