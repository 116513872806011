import React, { useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { Grid } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select } from "@material-ui/core";
import ToretaApi from "../../ToretaApi";
import { DebitRuleBody } from "../../common/interface";
type Props = {
    open: boolean;
    data: object | null;
    close: () => void;
    search: () => void;
};
const defaultValue: DebitRuleBody = {
    name: "",
    type: 1,
    minSeats: 0,
    price: 0,
    dinnerMinSeats: 0,
    dinnerPrice: 0,
    dates: [],
    restaurantId: 0,
};
const FormDialog: React.FunctionComponent<Props> = ({ open, data, close, search }) => {
    const { t } = useTranslation();
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const [form, setForm] = React.useState<DebitRuleBody>(defaultValue);

    useEffect(() => {
        if (data !== null) {
            setForm({ ...form, ...data });
        }
        // eslint-disable-next-line
    }, [data]);
    const [selectedDate, setSelectedDate] = React.useState<any>(null);

    const handleDateChange = (date: any, value?: string | null | undefined) => {
        setSelectedDate(date);
        // setFormatdate(value);
    };
    const disableDays = (date: any) => {
        return false;
    };
    const updateDates = () => {
        const formatdate = selectedDate.format("YYYY-MM-DD");
        if (form.dates.filter((a: string) => a === formatdate).length === 0) {
            form.dates.push(formatdate);
            form.dates.sort();
            setForm({ ...form });
        }
    };
    const deleteDates = (index: number) => {
        if (isManageLevel) {
            form.dates.splice(index, 1);
            setForm({ ...form });
        }
    };
    const updateData = () => {
        const IsBlank = (value: string | number) => {
            if (typeof value === "number") {
                return value < 0;
            } else {
                return !value || value === undefined || value === "";
            }
        };
        // console.log(form);
        if (IsBlank(form.price)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-001"),
            });
            return;
        } else if (IsBlank(form.minSeats)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-002"),
            });
            return;
        } else if (IsBlank(form.name)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-003"),
            });
            return;
        } else {
            const successAction = () => {
                search();
                close();
            };
            const sendform: DebitRuleBody = { ...form };
            if (sendform.type === 1) {
                sendform.dates = [];
            }
            toretaApi.updateDebitrule(form.restaurantId, sendform, successAction);
        }

        //
    };
    const dateAddDisabled = !selectedDate?._isValid;

    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{form.id ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.ADD")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>

                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.DEBITRULE.NAME")}
                            type="text"
                            value={form.name}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, name: event.target.value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        {form.type ? t(`MANAGER.DEBITRULE.RULE${form.type}`, form) : "None"}
                        <hr />
                    </Box>
                    <Box p={1}>
                        {form.type ? t(`MANAGER.DEBITRULE.DINNER_RULE${form.type}`, form) : "None"}
                        <hr />
                    </Box>
                    <Box p={1}>
                        <Select
                            value={form.type}
                            onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                setForm({ ...form, type: event.target.value as number });
                            }}
                        >
                            <MenuItem value={1}>{t("MANAGER.DEBITRULE.TYPE1")}</MenuItem>
                            <MenuItem value={2}>{t("MANAGER.DEBITRULE.TYPE2")}</MenuItem>
                        </Select>
                    </Box>

                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("MANAGER.DEBITRULE.LUNCH_PREFIX") + t("MANAGER.DEBITRULE.PEOPLE")}
                                    type="number"
                                    value={form.minSeats}
                                    fullWidth
                                    onChange={(event) => {
                                        setForm({
                                            ...form,
                                            minSeats: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("MANAGER.DEBITRULE.PRICE")}
                                    type="number"
                                    value={form.price}
                                    fullWidth
                                    onChange={(event) => {
                                        setForm({
                                            ...form,
                                            price: event.target.value,
                                        });
                                    }}
                                />
                                </Grid>
                        </Grid>
                    </Box>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("MANAGER.DEBITRULE.DINNER_PREFIX") + t("MANAGER.DEBITRULE.PEOPLE")}
                                    type="number"
                                    value={form.dinnerMinSeats}
                                    fullWidth
                                    onChange={(event) => {
                                        setForm({
                                            ...form,
                                            dinnerMinSeats: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label={t("MANAGER.DEBITRULE.PRICE")}
                                    type="number"
                                    value={form.dinnerPrice}
                                    fullWidth
                                    onChange={(event) => {
                                        setForm({
                                            ...form,
                                            dinnerPrice: event.target.value,
                                        });
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>

                    {form.type === 2 ? (
                        <Box p={1}>
                            <Grid container spacing={2}>
                                {isManageLevel ? (
                                    <>
                                        <Grid item xs={6}>
                                            <KeyboardDatePicker
                                                variant="inline"
                                                format="YYYY-MM-DD"
                                                margin="normal"
                                                fullWidth
                                                disableToolbar
                                                autoOk={true}
                                                minDate={moment()}
                                                id="date-picker-inline"
                                                label={t("MANAGER.DEBITRULE.DATE")}
                                                value={selectedDate}
                                                shouldDisableDate={disableDays}
                                                onChange={handleDateChange}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change date",
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Box p={1}>
                                                <Button onClick={updateDates} style={{ marginTop: "16px" }} variant={"contained"} disabled={dateAddDisabled} color="primary">
                                                    {t("MANAGER.ACTION.ADD") + t("MANAGER.DEBITRULE.DATE")}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </>
                                ) : null}
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        {form.dates.map((date: string, index: number) => {
                                            return (
                                                <Grid item xs={3}>
                                                    <Button onClick={() => deleteDates(index)} variant={"contained"} color="secondary">
                                                        {`${date}`}
                                                    </Button>
                                                </Grid>
                                            );
                                        })}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <></>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                    {isManageLevel ? (
                        <Button onClick={updateData} color="primary">
                            {t("MANAGER.ACTION.OK")}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FormDialog;
