import React, { useEffect } from "react";
import { Theme, createStyles, makeStyles,useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../ToretaApi";
import Dialog from "./CourseDialog";
import AutocompleteField from "../../Components/AutocompleteField";
import { useSelector } from "react-redux";
import IconDragHandle from "@material-ui/icons/Dehaze";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { AnyARecord } from "dns";
import { useCourseContext } from './CourseContext';
import { RestaurantCourseSetting } from "../../common/interface";
import { useImageCropContext } from '../../Components/ImageResize/providers/ImageCropProvider';
import { StripeRestaurant, Course, ShopDetail } from "../../common/interface";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        
        container: {
            maxHeight: window.innerHeight - 280,
            position:'relative',
            [theme.breakpoints.down("md")]: {
                maxHeight: window.innerHeight - 280,
            }
        },
    })
);
type Props = {
    data: StripeRestaurant | null;
};
const CourseTable :React.FunctionComponent<Props> = ({data}) => {
    const {settingCourselist,updateCourselist } = useCourseContext();
    var droppedItemIndex:number = 0;
    var droppedElement:any =null;
    function updateCourseList(datas: any[], idToInsert: number, targetIndex: number): any[] {
        // Find the index of the item with idToInsert
        /*
        if(targetIndex=-1) targetIndex = 0;
        const insertIndex = datas.findIndex(item => item.id === idToInsert);
      
        if (insertIndex === -1) {
          console.log(`Item with id ${idToInsert} not found.`);
          return datas;
        }
        */
        // Remove the item with idToInsert from its current position
        const removedItem = datas.splice(idToInsert, 1)[0];
      
        // Insert the removed item at the targetIndex
        datas.splice(targetIndex, 0, removedItem);
      
        // Update order values
        datas.forEach((item, index) => {
          item.order = index + 1;
        });
        //Re-order order ASC
        datas = datas.sort((a, b) => a.order - b.order);
        return datas;
      }
      
    const classes = useStyles();
    const { t } = useTranslation();         
    const [dialogStatus, setDialogStatus] = React.useState<any>({ open: false , data: null });
    //const [orderedRows, setOrderedRows] = React.useState<any>(courselist);
    const dialogClose = () => {
        setDialogStatus({ open: false, data: null,restaurentItem:null });
    };
    const dialogOpen = (data:any) => {
        setDialogStatus({ open: true , data: { ...data }, restaurentItem:{...data} });
    };
    function isMobile() {
        return window.matchMedia("(pointer:coarse)").matches;
    }
    const handleRowReorder = (draggedRowId: any, event: any) => {
        if(isMobile()) return;
        const insertIndex = settingCourselist.findIndex(item => item.id === draggedRowId);
        //console.log(`MoveItemIndex:${insertIndex}`);
        if(insertIndex===droppedItemIndex) return;
        var updatedRows = [...settingCourselist];
        updatedRows = updateCourseList(updatedRows, insertIndex ,droppedItemIndex);
        updateCourselist(updatedRows);
      };
    const handleDragStart=(raggedRowId: any,event: any)=>{
        if(isMobile()) return;
        const x = event.clientX;
        const y = event.clientY;
        const target = document.elementFromPoint(x, y);
        if(target && (target.tagName =='svg' || target.tagName =='path' || target.classList.contains('draggable-icon'))){
            return;
        }
        event.preventDefault();
    }
    const handleDragOver=(raggedRowId: any,event: any)=>{
        if(isMobile()) return;
        const insertIndex = settingCourselist.findIndex(item => item.id === raggedRowId);
        droppedItemIndex = insertIndex;
        //console.log(`droppedItemIndex:${droppedItemIndex}`);
    }
    var clientX = 0;
    var clientY = 0;  
    var prev_top =0;
    
    const container = document.getElementById('drag-container');
    const tab1 :any= document.getElementById('full-width-tabpanel-1');
    const findClosestTD=(childElement:any) =>{
        let parentElement = childElement.parentNode;
        while (parentElement && parentElement.tagName !== 'TR') {
          parentElement = parentElement.parentNode;
        }
        return parentElement;
      }
    const handleTouchStart = (rowId:any,event: any) => {
         if(container!=null){
            container.innerHTML ='';
         }
        var dragContainer= null;
        const parentNode = findClosestTD(event.target);
        if(parentNode!=null){
            dragContainer = parentNode.cloneNode(true);
        }
        else{
            dragContainer = event.target.parentNode.cloneNode(true);
        }
        if(container!=null){
            container.appendChild(dragContainer);
            clientX = event.touches[0].clientX;
            clientY = event.touches[0].clientY;
            container.style.left =  `${clientX}px`;
            container.style.top =  `${clientY}px`;
            container.style.display='none';
            container.style.width = `${tab1.clientWidth -10}px`;
        }
        prev_top = clientY;
      };

    const handleTouchMove = (rowId: any,event: React.TouchEvent<HTMLDivElement>) => {
        clientY = event.touches[0].clientY;
        clientX = event.touches[0].clientX;
        if(container!=null){
            const offsetLeft = tab1!=null? tab1.offsetParent.offsetLeft:0;
            const clientHeight = container.clientHeight/2;
            container.style.left =  `${(offsetLeft+30)}px`;
            container.style.top =  `${clientY - clientHeight}px`;
            container.style.display='';
        }
    };
    const handleTouchEnd = (rowId: any,event: React.TouchEvent<HTMLDivElement>) => {
        let x = clientX;
        let y = clientY;
        const isBefore = prev_top < y;
        // Lưu vị trí mới
        if(container!=null){
            container.innerHTML ='';
        }

        const draggableElements =  Array.from(document.querySelectorAll('.draggable'));
        const elements = document.querySelectorAll('.draggable');
        var elmIndex = -1;
        for (const element of draggableElements) {
            const elementRect = element.getBoundingClientRect();
            if (x >= elementRect.left && x <= elementRect.right &&
                y >= elementRect.top && y <= elementRect.bottom) {
                
                // Found the element!
                //console.log(element); // Or do whatever you want with the element
                elmIndex = Array.prototype.indexOf.call(elements, element);
                //console.log(elmIndex);
                break; // Stop further iteration
                }
        }    
        //Before 
        
        if(elmIndex==-1) return;
        const droppedItemIndex = elmIndex;
        const insertIndex = settingCourselist.findIndex(item => item.id === rowId);
        ///console.log(`${droppedItemIndex}:${insertIndex}`);
        if(droppedItemIndex == insertIndex) return;
        var updatedRows = [...settingCourselist];
        updatedRows = updateCourseList(updatedRows, insertIndex ,droppedItemIndex);
        updateCourselist(updatedRows);

    };

    const search = () => {
        const sendFilter = {
            
        };
    };
    const setImageUrl=()=>{

    };
return (
    <Grid item xs={12}>
    <Dialog open={dialogStatus.open} data={dialogStatus.data}  restaurentItem={data} close={dialogClose} search={search} />
	<TableContainer className={classes.container}>
		<Table stickyHeader aria-label="sticky table">
			<TableHead>
				<TableRow>
                    <TableCell className="course-header-left"></TableCell>
					<TableCell >{t("MANAGER.COURSE.GRID.APP_NAME")}</TableCell>
                    <TableCell className="course-header-right" colSpan={2}>{t("MANAGER.COURSE.GRID.APP_NAME_MODIFY")}</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{settingCourselist.map((row: any) => {
					return (
						<TableRow className="draggable" hover draggable tabIndex={-1} key={row.id} 
                        onDragStart={ (event) => handleDragStart(row.id,event)} 
                        onDragOver={ (event) => handleDragOver(row.id,event)} 
                        onDragEnd={(event) => handleRowReorder(row.id,event)}
                        >
                        <TableCell width={30} valign="middle" className="draggable-icon"
                            onTouchStart={ (event) => handleTouchStart(row.id,event)} 
                            onTouchMove={ (event) => handleTouchMove(row.id,event)} 
                            onTouchEnd={ (event) => handleTouchEnd(row.id,event)} 
                        >
                            <IconDragHandle className="middle-cell" htmlColor="#3F51B5" aria-selected={false} />
                            </TableCell>
                        <TableCell width="50%"  draggable={false}>{row.course_name}</TableCell>
                        <TableCell width="50%"  className="td-longText" ><div className="longText" >{row.course_name_new}</div></TableCell>
                        <TableCell width={100} >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => { dialogOpen(row); }}
                            >
                            {t("MANAGER.ACTION.EDIT")}
                            </Button>
                        </TableCell>
						</TableRow>
					);
				})}
			</TableBody>
		</Table>
	</TableContainer>
    <table id="drag-container"></table>
</Grid>
);
};
export default CourseTable;
