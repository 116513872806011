import React from "react";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

type Props = {
    file: File | null;
    fileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    accept?: string;
};

const ImageUploadButton: React.FunctionComponent<Props> = ({ file, fileChange, accept = "image/png, image/jpeg" }) => {
    const { t } = useTranslation();
    return (
        <>
            <label htmlFor="upload-photo">
                <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" accept={accept} onChange={fileChange} />
                <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended">
                    <AddIcon /> {t("MANAGER.FIELD.PIC")}
                </Fab>
            </label>
            <br />
            <span>{file?.name}　</span>
        </>
    );
};
export default ImageUploadButton;
