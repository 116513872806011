import * as React from 'react';
import { useState } from 'react';
import { Box, MenuItem, Input, Select, Chip, FormControlLabel, Switch, FormControl, InputLabel, Grid } from "@material-ui/core";
//import user1 from '@/assets/user_1.png';
import Modal from '../ImageResize/base/Modal';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { readFile } from '../ImageResize/helpers/cropImage';
import ImageCropModalContent from '../ImageResize/ImageCropModalContent';
import { useImageCropContext } from '../ImageResize/providers/ImageCropProvider';

const ImageCrop = ({onImageUrlChange,onDone, onCancel}) => {
  const [openModal, setOpenModal] = useState(false);
  const [preview, setPreview] = useState(null);
  const [editable, setEditable] = React.useState(false);
  const { getProcessedImage, setImage, resetStates } = useImageCropContext();
  const handleimageChange=()=>{
    onImageUrlChange();
    setEditable(false);
  }
  const handleDone = async () => {
    const avatar = await getProcessedImage();
    setPreview(window.URL.createObjectURL(avatar));
    resetStates();
    setOpenModal(false);
    onDone(avatar);
    setEditable(true)
  };
  const handleCancel=()=>{
    setOpenModal(false);
    onCancel();
    setEditable(false)
    resetStates();
  }
  return (
    <div className="bg-gray-100 h-screen flex justify-center items-center">
      {editable?(
      <>
      <label htmlFor="avatarInput" className="cursor-pointer">
        <img
          src={preview}
          height={365}
          width={365}
          className="object-cover rounded-full h-48 w-48"
          alt="" 
        />
      </label>
      </>
      ):(<></>)
      }
        <ImageCropModalContent onImageUrlChange={handleimageChange} handleDone={handleDone} handleClose={handleCancel} />
    </div>
  );
};

export default ImageCrop;