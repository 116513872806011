import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Typography, Button, TextField, CssBaseline } from "@material-ui/core";
import ToretaApi from "../ToretaApi";
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: theme.spacing(15),
        marginBottom: theme.spacing(3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
function ForgetPassword() {
    const { t } = useTranslation();
    const toretaApi = ToretaApi();
    const [inputs, setInputs] = useState({
        email: "",
    });
    const classes = useStyles();
    const handleInput = (e: any) => {
        e.persist();
        setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    };
    const successAction = () => {
        Swal.fire({
            icon: "success",
            title: t("MANAGER.MESSAGE.EMAIL_SENT"),
        }).then((confirm) => {
            if (confirm) {
                window.location.replace("/");
            }
        });
    };
    const sendForgetPasswordEmail = () => {
        toretaApi.forceResetUserPassword(inputs.email, successAction);
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5"></Typography>
                <form className={classes.form} noValidate>
                    <TextField variant="outlined" margin="normal" required fullWidth id="email" label={t("MANAGER.LOGIN.ACCOUNT")} name="email" autoComplete="email" autoFocus onChange={handleInput} />
                    <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit} onClick={sendForgetPasswordEmail}>
                        {t("MANAGER.ACTION.RESET_PASSWORD")}
                    </Button>
                </form>
            </div>
        </Container>
    );
}
export default ForgetPassword;
