import React from "react";
import { Animated } from "react-animated-css";
import { useSelector } from "react-redux";
import { Box, Divider, Drawer, DrawerProps, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PersonIcon from "@material-ui/icons/Person";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import BusinessIcon from "@material-ui/icons/Business";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { /*NavLink,*/ useHistory, useLocation } from "react-router-dom";
import ToretaApi from "../ToretaApi";
import { drawerWidth } from "./drawerSettings";
import { ManageUser } from "../common/interface";

const useStyles = makeStyles((theme: Theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        //backgroundColor: "white",
        //minHeight: "calc( 100vh - 160px )",
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerBlock: {
        display: "flex",
        background: "none",
        ...theme.mixins.toolbar,
    },
    current: {
        backgroundColor: "#4faf4f5c",
        "&:hover": {
            backgroundColor: "#4faf4f5c",
        },
    },
    toolbar: theme.mixins.toolbar,
    menulist: {
        paddingTop: "0px",
        paddingBottom: "0px",
    },
}));

export default function ResponsiveDrawer({ ...props }: DrawerProps) {
    const open: boolean = useSelector((state: any) => state.sidemenu);
    const user: ManageUser = useSelector((state: any) => state.user);
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;
    //const [mobileOpen, setMobileOpen] = React.useState(false);
    const toretaApi = ToretaApi();
    /*const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };*/
    const drawer = (
        <div>
            <Box className={classes.drawerBlock} />
            <List className={classes.menulist}>
                <Divider />
                <ListItem
                    className={clsx({
                        [classes.current]: pathname === "/",
                    })}
                    button
                    key={"Reservation"}
                    onClick={() => {
                        history.push("/");
                    }}
                >
                    <ListItemIcon>
                        <EventNoteIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("MANAGER.MENU.RESERVE")} />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    className={clsx({
                        [classes.current]: pathname === "/payment",
                    })}
                    key={"PAYMENT"}
                    onClick={() => {
                        history.push("/payment");
                    }}
                >
                    <ListItemIcon>
                        <AttachMoneyIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("MANAGER.MENU.PAYMENT")} />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    className={clsx({
                        [classes.current]: pathname === "/debitrule",
                    })}
                    key={"DEBIT"}
                    onClick={() => {
                        history.push("/debitrule");
                    }}
                >
                    <ListItemIcon>
                        <LineStyleIcon />
                    </ListItemIcon>
                    <ListItemText primary={t("MANAGER.MENU.DEBITRULE")} />
                </ListItem>

                {user.companyOwner || user.admin || user.restaurantOwner ? (
                    <>
                        <Divider />
                        <ListItem
                            button
                            className={clsx({
                                [classes.current]: pathname === "/user",
                            })}
                            key={"USER"}
                            onClick={() => {
                                history.push("/user");
                            }}
                        >
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("MANAGER.MENU.USER")} />
                        </ListItem>
                    </>
                ) : (
                    <></>
                )}
                {user.admin ? (
                    <>
                        <Divider />
                        <ListItem
                            button
                            className={clsx({
                                [classes.current]: pathname === "/company",
                            })}
                            key={"COMPANY"}
                            onClick={() => {
                                history.push("/company");
                            }}
                        >
                            <ListItemIcon>
                                <BusinessIcon />
                            </ListItemIcon>
                            <ListItemText primary={t("MANAGER.MENU.COMPANY")} />
                        </ListItem>
                    </>
                ) : (
                    <></>
                )}
                <Divider />
                <ListItem button key={"LOGOUT"} onClick={toretaApi.UserLogout}>
                    <ListItemIcon>
                        <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={user.name + " " + t("MANAGER.MENU.LOGOUT")} />
                </ListItem>
            </List>
            <Box className={classes.drawerBlock} />
        </div>
    );
    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
                paper: classes.drawerPaper,
            }}
            {...props}
        >
            <Animated animationIn="fadeInLeft" animationInDuration={200} animationOut="fadeOut" isVisible={open}>
                {drawer}
            </Animated>
        </Drawer>
    );
}
