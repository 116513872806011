import React from "react";
import TimePicker, { TimePickerValue } from "react-time-picker";

interface TimeSpanPickerProps {
    children?: React.ReactNode;
    dir?: string;
    start_time: number | null | undefined;
    end_time: number | null | undefined;
    onStartTimeChange:any;
    onEndTimeChange:any;
}
const addPreZero = (num: number): string => {
  return ("00" + num).slice(-2);
};
const transformTimeNumber24h = (value: number | null | undefined): string => {
  //console.log("transformTimeNumber24h" , value);
  if (!value) {
    return "00:00";
  }
  const hour = Math.floor(value / 3600);
  return (
    addPreZero(hour >= 24 ? hour - 24 : hour) +
    ":" +
    addPreZero(Math.floor((value % 3600) / 60))
  );
};
function TimeSpanPicker( props: TimeSpanPickerProps) {
    const { children, start_time,end_time, onStartTimeChange,onEndTimeChange,...other } = props;

    const onTimeStartChange = (pickedTime: TimePickerValue) => {
        //console.log("start time:", pickedTime);
        if(pickedTime){
          onStartTimeChange(Date.parse(`1970-01-01T${pickedTime}:00+0000`) / 1000);
            //const t = pickedTime?hours() * 3600 + pickedTime?.minute() * 60;
        }else{
          onStartTimeChange(0);
        }
    };
    const onTimeEndChange = (pickedTime: TimePickerValue) => {
        //console.log("end time:", pickedTime);
        if(pickedTime){
          onEndTimeChange(Date.parse(`1970-01-01T${pickedTime}:00+0000`) / 1000);
            //const t = pickedTime?hours() * 3600 + pickedTime?.minute() * 60;
        }else{
          onEndTimeChange(0);
        }
    };
    return (
    <span {...other}>
          <TimePicker disableClock clearIcon={null} locale="en-US"
              value={ transformTimeNumber24h(start_time) } 
              amPmAriaLabel="Select AM/PM" onChange={onTimeStartChange} />
          <span> ~ </span>
          <TimePicker disableClock clearIcon={null} locale="en-US"
              value={ transformTimeNumber24h(end_time) } 
              amPmAriaLabel="Select AM/PM" onChange={onTimeEndChange} />
    </span>
    );
}
export type { TimeSpanPickerProps };
export { TimeSpanPicker };
export default TimeSpanPicker;