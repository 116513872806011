import React, { createContext, useContext, useState } from 'react';
import { isBuffer } from 'util';
import { RestaurantCourseSetting } from '../../common/interface';

interface CourseContextType {
    settingCourselist: RestaurantCourseSetting[];
    updateCourselist: (newList: RestaurantCourseSetting[]) => void;
  }

const CourseContext = createContext<CourseContextType >({
  settingCourselist: [],
  updateCourselist: (newList: RestaurantCourseSetting[])=>{},
});

export const useCourseContext = () => {
    const context = useContext(CourseContext);
    if (!context) {
      throw new Error('useCourseContext must be used within a CourseProvider');
    }
    return context;
  };
  
  export const PaymentProvider: React.FC = ({ children }) => {
    const [settingCourselist, setCourselist] = useState<RestaurantCourseSetting[]>([]);
  
    const updateCourselist = (newList: RestaurantCourseSetting[]) => {
     if(newList ==undefined) newList =[];
      setCourselist(newList);
    };
    
    return ( 
      <CourseContext.Provider value={{ settingCourselist, updateCourselist }}>
        {children}
      </CourseContext.Provider>
    );
  };

interface Map {
    [key: string]: any;
    [index: number]: any;
}
const dicCurrency:Map={
    "SGD":"S$",
    "TWD":"NT$",
    "MYR":"RM",
    "THB":"฿",
    "JPY":"¥",
}
const formatter = new Intl.NumberFormat('ja-JP', {
  //style: 'currency',
  currency: 'JPY',
});
const formatterEn = new Intl.NumberFormat('en-US', {
  currency: 'USD',
});
const getCurrencyAmt = (currency:string,amt: number) =>{
  if(amt==null) return '0';
  if(currency=="JPY"){
      return formatter.format(Math.trunc(amt));
  }
  return formatterEn.format(amt);
}
export const formatCurrencyAmt = (companyCountry:string, currency:string, amt: number) =>{
  if(companyCountry !='jp'){
    return `${currency} ${amt||0}`;
  }
  return `${getCurrency(currency)} ${getCurrencyAmt(currency, amt)}`;
}
export const getCurrency = (key: string) =>{
  var c= Object.keys(dicCurrency).filter((val,ind) => {return val==key;});
  if(c.length>0){
      return dicCurrency[key];
  }
  return key;
}