import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Switch, Input, Select, Chip, FormControl, InputLabel, FormControlLabel, Typography } from "@material-ui/core";
import { createStyles, makeStyles, useTheme, Theme } from "@material-ui/core/styles";
import ToretaApi from "../../ToretaApi";
import { ManageUser, MenuOption, ManagerUserBody } from "../../common/interface";
import { CheckBox } from "@material-ui/icons";
import { DatePicker, DateTimePicker } from "@material-ui/pickers";
import { format,parse  } from 'date-fns';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },      
        chip: {
            margin: 2,
        },
        redchip: {
            margin: 2,
            color: "red",
            textDecorationLine: 'line-through'
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
function getStyles(restaurant: MenuOption, restaurantsIds: number[], theme: Theme) {
    return {
        fontWeight: restaurantsIds.find((a) => a === restaurant.value) ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

type Props = {
    open: boolean;
    userId: number | null;
    publicKey?: string| null ;
    close: () => void;
    search: () => void;
};
interface filterData{
    fromDate: number | undefined,
    toDate: number| undefined,
}
const defaultValue: filterData = {
    fromDate: Date.now(),
    toDate: Date.now()
};
const DialogCSV: React.FunctionComponent<Props> = ({ open, userId, publicKey, close, search }) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const user: ManageUser = useSelector((state: any) => state.user);
    const toretaApi = ToretaApi();
    const [form, setForm] = React.useState<filterData>(defaultValue);
    
    useEffect(() => {
        setForm({ ...defaultValue});
    }, [userId]);

    const searchData = async()=>{
        const datas= await toretaApi.searchTrackingEmail(form.fromDate, form.fromDate);
        console.log(datas);
        const sysDate = new Date(form.fromDate as number);
        exportDataToCSV(datas,`SES-EMAIL-REPORT_${format(sysDate,'yyyyMM')}`);
    }
    function exportDataToCSV(data: any[], fileName: string) {
        // Kiểm tra dữ liệu đầu vào
        if (!data || !data.length) {
            data=[{email:'',companyName:'',countryCode:'',sendDate:''}];
        }
      
        // Lấy tên các cột từ đối tượng đầu tiên
        const headers = Object.keys(data[0]);
      
        // Tạo dòng tiêu đề
        let csvContent = headers.join(',') + '\n';
        csvContent = csvContent.replace("email","Email");
        csvContent = csvContent.replace("companyName","Company Name");
        csvContent = csvContent.replace("countryCode","Country Code");
        csvContent = csvContent.replace("sendDate","Send Date");
        // Tạo các dòng dữ liệu
        data.forEach(item => {
          const row = headers.map(header => {
            if(header==='sendDate' && item[header]!=''){

                const date =new Date(item[header]);
                const parsedDate = format(date, 'yyyy-MM-dd HH:mm:ss');
                return `"${parsedDate}"`;
            }
            return `"${item[header]}"`;
          }).join(',');
          csvContent += row + '\n';
        });
      
        // Tạo một Blob và tải xuống file
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.csv`);
        document.body.appendChild(link);
        link.click(); 

      }
    const canDo = user.admin || user.companyOwner;
    return (
        <div >
            <Dialog className="export-csv-dialog" open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("MANAGER.EXPORT_CSV.DIALOG-TITLE")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Typography className="opt-page-title" component="h6" variant="h6">
                    {t("MANAGER.EXPORT_CSV.MONTH")}</Typography>
                    <Box p={1}>
                    <DatePicker 
                            views={['month']}
                            disableFuture
                            value={form.fromDate}
                            format="YYYY-MM"
                            color="primary"
                            onChange={(event) => {
                                setForm({ ...form, fromDate: event?.valueOf()});
                            }}
                            >
                            </DatePicker>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.EXPORT_CSV.BTN_CANCEL")}
                    </Button>
                    <Button onClick={searchData} color="primary">
                        {t("MANAGER.EXPORT_CSV.BTN_OK")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default DialogCSV;
