import React, { ReactNode } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import SideMenu from "./SideMenu";
import { drawerWidth } from "./drawerSettings";

export interface ContainerViewProps {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    //container?: Element;
    children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    contentShift: {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
    },
}));

function ContainerView({ children }: ContainerViewProps) {
    const menuOpen: boolean = useSelector((state: any) => state.sidemenu);
    const classes = useStyles();
    return (
        <>
            <SideMenu />
            <Box className={clsx(classes.content, { [classes.contentShift]: menuOpen })}>{children}</Box>
        </>
    );
}

export default ContainerView;
