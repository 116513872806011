import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import AutocompleteField from "../../Components/AutocompleteField";
import ToretaApi from "../../ToretaApi";
import Rtable from "./RTable";
import "./override.css"
function Reservations() {
    const { t } = useTranslation();
    const user: any = useSelector((state: any) => state.user);
    const toretaApi = ToretaApi();
    useEffect(() => {
        toretaApi.getCompanyList().then((data) => {
            setCompanyList(data);
        });
        // eslint-disable-next-line
    }, []);
    const storeCompanyCountry = (companyid: number|null) => {
        
        var company=companyList.filter((item:any)=>{return item.value==companyid});
        if(company.length>0){
            user.select_country=company[0].country;
        }
    };
    const [company, setCompany] = React.useState<number | null>(null);
    const [restaurant, setRestaurant] = React.useState<number | null>(null);
    const [companyList, setCompanyList] = React.useState<any>(null);
    const [restaurantList, setRestaurantList] = React.useState<any>(null);
    useEffect(() => {
        if (!user.admin && user.companyId) {
            setCompany(user.companyId);
        }
    }, [user]);
    useEffect(() => {
        toretaApi.getRestaurantList(company).then(setRestaurantList);
        // eslint-disable-next-line
    }, [company]);
    useEffect(() => {
        if (restaurantList?.length === 1) {
            setRestaurant(restaurantList[0].value);
        }
    }, [restaurantList]);

    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} lg={3}>
                    <AutocompleteField
                        label={t("MANAGER.FIELD.COMPANY")}
                        optionList={companyList}
                        value={company}
                        onChange={(value: any) => {
                            setCompany(value);
                            storeCompanyCountry(value);
                            setRestaurant(null);
                        }}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <AutocompleteField label={t("MANAGER.FIELD.RESTAURANT")} optionList={restaurantList} value={restaurant} onChange={setRestaurant} disabled={!company} />
                </Grid>
                {restaurant && restaurant > 0 ? (
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <Rtable restaurantId={restaurant} companyId={company as number} />
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    );
}
export default Reservations;
