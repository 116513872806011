import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {  Box } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

type Props = {
    open: boolean;
    close: () => void;
    search: () => void;
};
const ReportDialog: React.FunctionComponent<Props> = ({ open, close, search }) => {
    const { t } = useTranslation();
    const downloadButton = () => {
        window.open(`${process.env.REACT_APP_API_URL}/api/download/report/sms/${selectedDate.getFullYear()}/${selectedDate.getMonth()+1}`);
    };
    const [selectedDate, setSelectedDate] = React.useState(
        new Date()
      );
    const handleDateChange = (date: MaterialUiPickersDate) => {
        setSelectedDate(date?.toDate() || new Date());
    };
    useEffect(() => {
        
    }, [selectedDate]);
    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("MANAGER.MENU.SMS_MONTHLY_REPORT")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    
                    <DatePicker label={t("MANAGER.FIELD.NEED_MONTH")}   variant="inline"
                        openTo="month"
                        views={["year", "month"]}
                        value={selectedDate}
                        onChange={handleDateChange} />
                    <Box p={1}>
                        <Button variant="contained" color="secondary" onClick={downloadButton}>
                                {t("MANAGER.ACTION.DOWNLOAD")}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ReportDialog;
