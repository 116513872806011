import React, { useRef, useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Typography, Button, TextField, CssBaseline, Link, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useEffect } from "react";
import ToretaApi from "../ToretaApi";
import { updateUser } from "../Actions/Actions";
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: theme.spacing(15),
        marginBottom: theme.spacing(3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function ConfirmOTP() {
    const { t } = useTranslation();
    // let defaultAlertMessage = "";
    const user = useSelector((state: any) => state.user);
    const confirmOTPRef = useRef(null);

        useEffect(() => {
        if (confirmOTPRef.current) {
        const handleBeforeUnload = () => {
            toretaApi.UserLogout();
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);   

        };
        }
    }, [confirmOTPRef]);   

        const toretaApi = ToretaApi();
        const [inputs, setInputs] = useState({
            email: "",
            otp: "",
        });

    const classes = useStyles();
    const handleInput = (e: any) => {
        e.persist();
        setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    };
    const doLogin = () => {
        if(!inputs.otp){
            Swal.fire({
                icon: "error",
                title: t("MANAGER.OTP.OTP-EMPTY"),
            });
        }
        else if(inputs.otp.length!==6 || parseInt(inputs.otp).toString().length!==6 ){
            Swal.fire({
                icon: "error",
                title: t("MANAGER.OTP.OTP-INVALID"),
            });
        }
        else{
        toretaApi.ConfirmOTP(inputs.otp);
        }
    };
    return (
        <Container ref={confirmOTPRef} component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography className="opt-page-title" component="h6" variant="h6">
                {t("MANAGER.OTP.PAGE_TITLE")}
                </Typography>
               
                <form className={classes.form} noValidate>
                    <br/>
                    <TextField variant="outlined" margin="normal" fullWidth id="otp" label={t("MANAGER.OTP.OTP_PLACEHODLER")} name="otp" autoFocus onChange={handleInput} />
                    <br/>
                    <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit} onClick={doLogin}>
                        {t("MANAGER.OTP.OTP_BUTTON_CONFIRM")}
                    </Button>
                    
                </form>
                <br/>
                <Typography component="h6" variant="h6">
                    <div className="opt-page-note-title">{t("MANAGER.OTP.PAGE_DESCRITION_TITLE")}</div>
                    <ul className="opt-page-note">
                     {t("MANAGER.OTP.PAGE_DESCRITION").split("\n").map((line) => <li>{line}</li>)}
                     </ul>
                </Typography>
            </div>
        </Container>
    );
}
export default ConfirmOTP;
