import DEF from "../def";
import { localLang } from "../../common/Const/LangList";
const initialState = localLang;

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.LANG_CHANGE:
            return action.value;
        default:
            return state;
    }
}