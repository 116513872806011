import React, { useState, useEffect, useRef } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Container, Typography, Button, TextField, CssBaseline } from "@material-ui/core";
import ToretaApi from "../ToretaApi";
import LoginPage from "../Pages/Login";

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: theme.spacing(15),
        marginBottom: theme.spacing(3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
function ResetPassword() {
    const { t } = useTranslation();
    const toretaApiRef = useRef(ToretaApi());
    const toretaApi = toretaApiRef.current;
    const history = useHistory();
    // const toretaApi = ToretaApi();
    const [inputs, setInputs] = useState({
        password: "",
    });
    const [isUsed, setIsUsed] = React.useState(true);
    const getCode = () => {
        let queryString = window.location.search;
        queryString = queryString.replace("?", "");
        let values = queryString.split("&");
        let i = 0;
        for(i = 0 ; i < values.length ; i++) {
            const arr = values[i].split("=");
            if(arr[0] === "code") {
                return arr[1];
            }
        }
        return "";
    }
    const code = getCode();
    useEffect(() => {
        toretaApi.isCodeUsed(code).then((data) => {
            if(data) {
                setIsUsed(true);
            } else {
                setIsUsed(false);
            }
        });
    }, [code, toretaApi]);

    const classes = useStyles();
    const handleInput = (e: any) => {
        e.persist();
        setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    };
    
    const doResetPassword = () => {
        toretaApi.resetPasswordByCode(inputs.password, code)
            .then((data) => {
                if(data) {
                    window.history.pushState("", document.title, "/");
                    window.location.reload();
                }
            });
    };

    if(!isUsed) {
        return (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Typography component="h1" variant="h5"></Typography>
                    <form className={classes.form} noValidate>
                        <TextField 
                            id="username"
                            autoComplete="username"
                            type="hidden"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required fullWidth name="password"
                            label={t("MANAGER.LOGIN.PASSWORD")}
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleInput}
                        />
                        <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit} onClick={doResetPassword}>
                            {t("MANAGER.LOGIN.RESET_PASSWORD")}
                        </Button>
                    </form>
                </div>
            </Container>
        );
    } else {
        return (
            <LoginPage />
        ); 
    }
}
export default ResetPassword;
