import React, { useEffect } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Select, Grid, InputLabel } from "@material-ui/core";
import ToretaApi from "../../ToretaApi";
type Props = {
    open: boolean;
    restaurantId: number;
    rules: any[] | null;
    close: () => void;
    success: () => void;
};

const defaultValue = {
    startDate: null,
    endDate: null,
    weekDay: 0,
    ruleId: 0,
};
const RegularDialog: React.FunctionComponent<Props> = ({ open, rules, restaurantId, close, success }) => {
    const { t } = useTranslation();
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const [form, setForm] = React.useState(defaultValue);
    const weekdayOptions = t("WEEKS", { returnObjects: true }) as string[];

    useEffect(() => {
        setForm({ ...defaultValue });
    }, [open]);

    const updateData = () => {
        const successAction = () => {
            success();
            close();
        };
        const sendform = { ...form };
        if (!sendform.startDate || !sendform.endDate) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-007"),
            });
        } else if (moment(sendform.endDate).isBefore(sendform.startDate)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-007"),
            });
        } else if (sendform.ruleId === 0) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.DEBITRULE-008"),
            });
        } else {
            toretaApi.updateWeekDayDebitRule(restaurantId, sendform, successAction);
        }
    };

    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {t("MANAGER.ACTION.QUICK_SETTING")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="YYYY-MM-DD"
                                    margin="normal"
                                    fullWidth
                                    autoOk={true}
                                    minDate={moment()}
                                    id="date-picker-inline"
                                    label={t("MANAGER.DEBITRULE.START_DATE")}
                                    value={form.startDate}
                                    onChange={(date: any) => {
                                        setForm({ ...form, startDate: date.format("YYYY-MM-DD") });
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    variant="inline"
                                    format="YYYY-MM-DD"
                                    margin="normal"
                                    fullWidth
                                    autoOk={true}
                                    minDate={moment()}
                                    id="date-picker-inline"
                                    label={t("MANAGER.DEBITRULE.END_DATE")}
                                    value={form.endDate}
                                    onChange={(date: any) => {
                                        setForm({ ...form, endDate: date.format("YYYY-MM-DD") });
                                    }}
                                    KeyboardButtonProps={{
                                        "aria-label": "change date",
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Box p={1}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <InputLabel>{t("MANAGER.FIELD.RULE")}</InputLabel>
                                <Select
                                    value={form.ruleId}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                        setForm({ ...form, ruleId: event.target.value as number });
                                    }}
                                >
                                    <MenuItem value={0}>None</MenuItem>
                                    {rules?.map((a, index) => {
                                        return <MenuItem key={index} value={a.id}>{a.name}</MenuItem>;
                                    })}
                                </Select>
                            </Grid>
                            <Grid item xs={6}>
                                <InputLabel>{t("MANAGER.FIELD.DAY")}</InputLabel>
                                <Select
                                    value={form.weekDay}
                                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                                        setForm({ ...form, weekDay: event.target.value as number });
                                    }}
                                >
                                    {weekdayOptions.map((a: any, index: any) => {
                                        return <MenuItem key={index} value={index}>{a}</MenuItem>;
                                    })}
                                </Select>
                            </Grid>
                        </Grid>

                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                    {isManageLevel ? (
                        <Button onClick={updateData} color="primary">
                            {t("MANAGER.ACTION.OK")}
                        </Button>
                    ) : null}
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default RegularDialog;
