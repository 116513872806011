import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {  Box } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

type Props = {
    open: boolean;
    data: any;
    close: () => void;
    search: () => void;
};
const ReportDialog: React.FunctionComponent<Props> = ({ open, data, close, search }) => {
    const { t } = useTranslation();
    
    const downloadButton = () => {
        const restaurantId = data.restaurantId;
        
        window.open(`${process.env.REACT_APP_API_URL}/api/download/report/reservations/${restaurantId}/${moment(startAt).format('YYYY-MM-DD')}/${moment(endAt).format('YYYY-MM-DD')}`);
    };
    const [startAt, setStartAt] = React.useState(
        new Date()
      );
    const [endAt, setEndAt] = React.useState(
        new Date()
      );
    const handleStartDateChange = (date: MaterialUiPickersDate) => {
        setStartAt(date?.toDate() || new Date());
    };
    const handleEndDateChange = (date: MaterialUiPickersDate) => {
        setEndAt(date?.toDate() || new Date());
    };
    useEffect(() => {
        
    }, [startAt,endAt]);
    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{t("MANAGER.MENU.RESERVATION_REPORT")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    
                    <DatePicker label={t("MANAGER.FIELD.START_DATE")}   variant="inline"
                        openTo="date"
                        views={["year", "month","date"]}
                        value={startAt}
                        onChange={handleStartDateChange} />
                    <DatePicker label={t("MANAGER.FIELD.END_DATE")}   variant="inline"
                        openTo="date"
                        views={["year", "month","date"]}
                        value={endAt}
                        onChange={handleEndDateChange} />
                    <Box p={1}>
                        <Button variant="contained" color="secondary" onClick={downloadButton}>
                                {t("MANAGER.ACTION.DOWNLOAD")}
                        </Button>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default ReportDialog;
