import React from "react";
import Grid from "@material-ui/core/Grid";
import Rtable from "./RTable";

function Reservations() {
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} container spacing={2} alignItems="center">
                    <Rtable />
                </Grid>
            </Grid>
        </>
    );
}
export default Reservations;
