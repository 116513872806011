import DEF from "../def";

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.SHOP_UPDATE:             
            return {...action.value};
        default:
            return state;
    }
}