import { useEffect } from "react";
import * as React from 'react';
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import CourseImageCrop, { InputNumbericRange,InputChecklistRange ,InputDateRange, InputTimeRange, generateTimeArray, createSeatTypes, updateCheckedStatus, updateDateRange, joinCodes, InputTimeDropdownRange} from "../Payment/CourseImageCrop";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { Box, Input, Select, Chip, FormControlLabel, Switch, FormControl, InputLabel, Grid, Hidden,Typography } from "@material-ui/core";
import { createStyles, makeStyles, withStyles, useTheme, Theme } from "@material-ui/core/styles";
import ToretaApi from "../../ToretaApi";
import { StripeRestaurant, Course, ShopDetail, RestaurantCourseSetting, ToretaReservationsDaysResponse, ShopInfo } from "../../common/interface";
import { ImageUploadButton } from "../Payment/CourseComponent";
import { useSelector } from "react-redux";
import { formatCurrencyAmt, getCurrency, useCourseContext } from './CourseContext';
import { readFile } from '../../Components/ImageResize/helpers/cropImage';
import { CheckBox, CheckRounded, DateRange, Label } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import moment from "moment";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 120,
            maxWidth: 300,
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        }, 
        break_line:{ border:'dashed 1px #AEAEAE' },
        required:{color:'red','font-size':'1rem', 'font-weight':'700'},
        requiredAuth:{color:'red','font-size':'0.75rem', 'font-weight':'700'},
        groupPd15:{ marginTop:'15px'},
        groupPd20:{ marginTop:'20px'},
        groupPd30:{ marginTop:'30px'},
        groupPd40:{ marginTop:'40px'},
        SeatType:{ marginTop: '40px'},
        courseName:{

        },
        courseImage:{
        },
        coursePrice:{
            'padding-top':'5px',
            'font-size':'1rem',
            'marginBottom':'10px'
        },
        courseLabel:{
            'font-size':'1rem'
        },
        groupTile:{
            'font-size':'1rem'
        },
        note:{
            'font-size':'0.75rem'
        }
    })
);
                                                    
type Props = {
    open: boolean;
    data: RestaurantCourseSetting | null;
    restaurentItem: StripeRestaurant | null;
    close: () => void;
    search: () => void;
};
interface DialogStripeRestaurant extends StripeRestaurant {
    restaurantLink?:string | "";
    file?: File | null;
}
interface CheckDataItem{
    code:string,
    text: string,
    checked:boolean | false
}
interface NumbericItem{
    code:string,
    text: string,
    labelFrom:string|"",
    labelTo:string|"",
    valueFrom: number |null,
    valueTo: number | null,
    errorFrom:boolean|false,
    errorTo:boolean|false
}
interface CheckDataItem{
    code:string,
    text: string,
    checked:boolean | false,
    index?:number|0
}
interface StartTimeItem{
    code:string,
    text: string,
    value:string,
    nextDate:number,
    checked:boolean | false
}
interface RangeDateItem{
    code:string,
    text: string,
    valueFrom: Date |null,
    valueTo: Date | null,
}
interface RangeTimeItemW{
    code:string,
    text: string,
    labelFrom:string|"",
    labelTo:string|"",
    valueFrom: string |null,
    valueTo: string | null,
}
var seatTypeLanguage: { [id: string] : string; } = {};

const defaultValue:RestaurantCourseSetting={
    id:0,
    course_id: "",
    course_name: "",
    course_name_new:"",
    description: "",
    file: null,
    image: "",
    order: 0,
    price: 0,
    restaurant_id: 0,    
    numberOfPersonsMin:0,
    numberOfPersonsMax:0,
    seatTypes:null,
    startTimes:null,
    servicePeriodFrom:null,
    servicePeriodTo:null,
    exceptions:null,
    serviceDays:null,
    cutoffTimeFrom:null,
    cutoffTimeTo:null,
    authorizationHoldAmount:null
}
type TypeListRangControl ={
     ExceptionList:RangeDateItem[];
     NumberOfPersons:NumbericItem[];
     ServiceDays:CheckDataItem[];
     SeatTypes:CheckDataItem[];
     StartTimes: any[];
     ServicePeriod:RangeDateItem[];
     CutOffTimes:RangeTimeItemW[];
}
const ListRangControl:TypeListRangControl={
     ExceptionList:[],
     NumberOfPersons:[],
     ServiceDays:[],
     SeatTypes:[],
     StartTimes: [],
     ServicePeriod:[],
     CutOffTimes:[],
}
const DefaultListRangControl:TypeListRangControl={
    ExceptionList:[],
    NumberOfPersons:[],
    ServiceDays:[],
    SeatTypes:[],
    StartTimes: [],
    ServicePeriod:[],
    CutOffTimes:[],
}

const FormDialog: React.FunctionComponent<Props> = ({ open, data,restaurentItem, close, search }) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const user: any = useSelector((state: any) => state.user);
    const toretaApi = ToretaApi();
    const theme = useTheme();
    const {settingCourselist,updateCourselist } = useCourseContext();
    const [form, setForm] = React.useState<RestaurantCourseSetting>(defaultValue);
    const [editable, setEditable] = React.useState(true);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);
    const [listRangControl, setListRangControl] = React.useState(ListRangControl);
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const cutoffTimeWidth =["ja","zh-TW"].includes(currentLanguage)? 250:370;
    var  course_name :any = data?.course_name;
    console.log(currentLanguage);
    var CutOffTimeDaysDropdownItems:any[]=[
        {code:"0",value:"0", label:t('MANAGER.COURSE.DIALOG.CUTTIME_DATE.TODAY')},
        {code:"1",value:"1", label:t('MANAGER.COURSE.DIALOG.CUTTIME_DATE.1DAY')},
        {code:"2",value:"2", label:t('MANAGER.COURSE.DIALOG.CUTTIME_DATE.2DAY')},
        {code:"3",value:"3", label:t('MANAGER.COURSE.DIALOG.CUTTIME_DATE.3DAY')},
        {code:"7",value:"7", label:t('MANAGER.COURSE.DIALOG.CUTTIME_DATE.7DAY')},
    ];

    useEffect(() => {

        setDisplaySeats();
        setForm(defaultValue);
        var NumberOfPersons:NumbericItem[]=[
            {code:"NumberOfPersons", text:"Persons", labelFrom:"", labelTo:"Persons", valueFrom:null, valueTo:null, errorFrom:false, errorTo:false }
        ];

        var ServiceDays:CheckDataItem[]=[
            {code:"MON", text:"Monday", checked:false},
            {code:"TUE", text:"Tuesday", checked:false},
            {code:"WED", text:"Wednesday", checked:false},
            {code:"THU", text:"Thursday", checked:false},
            {code:"FRI", text:"Friday", checked:false},
            {code:"SAT", text:"Saturday", checked:false},
            {code:"SUN", text:"Sunday", checked:false},
            {code:"HOL", text:"Holiday", checked:false},
            {code:"WKDBEFORE", text:"Weekdays before public holiday", checked:false},
            {code:"HOLBEFORE", text:"Holiday before public holiday", checked:false}
        ];
        var ServicePeriod:RangeDateItem[]=[
            {code:"Tatami Seat", text:"Tatami Seat", valueFrom:null, valueTo:null}
        ];

        var ExceptionList:RangeDateItem[]=[
            {code:"Private Room", text:"Private Room", valueFrom:null, valueTo:null},
            {code:"Table Seat", text:"Table Seat",  valueFrom:null, valueTo:null},
            {code:"Counter Seat", text:"Counter Seat",  valueFrom:null, valueTo:null},
            {code:"Outdoor Seat", text:"Outdoor Seat",  valueFrom:null, valueTo:null},
            {code:"Tatami Seat", text:"Tatami Seat",  valueFrom:null, valueTo:null},
            {code:"Private Room1", text:"Private Room",  valueFrom:null, valueTo:null},
            {code:"Table Seat1", text:"Table Seat",  valueFrom:null, valueTo:null},
            {code:"Counter Seat1", text:"Counter Seat", valueFrom:null, valueTo:null},
            {code:"Outdoor Seat1", text:"Outdoor Seat",  valueFrom:null, valueTo:null},
            {code:"Tatami Seat1", text:"Tatami Seat",  valueFrom:null, valueTo:null}
        ];
        
        var CutOffTimes:RangeTimeItemW[]=[
            {code:"CutOffTimes", text:"CutOffTimes", labelFrom:"の", labelTo:"まで", valueFrom:null, valueTo:null}
        ];
        /*
        var seatCategories = shopinfo.tables?.map(p => ({
            key_name: p.key_name,
            display_name: p.display_name
        }));
        if(seatCategories){
            seatCategories.push({key_name: 'bar_counter', display_name: 'Counter Seat'});
            seatCategories.push({key_name: 'takeaway', display_name: 'Counter Seat'});
            seatCategories.push({key_name: 'semi_private', display_name: 'Counter Seat'});
            seatCategories.push({key_name: 'sofa_seat', display_name: 'Counter Seat'});
            seatCategories.push({key_name: 'horigotatsu', display_name: 'Counter Seat'});
        }
        console.log(seatCategories);
        */
        var SeatTypes:CheckDataItem[] = createSeatTypes(shopinfo.tables,seatTypeLanguage,function(e:any){return t(e);});
        
        for (const item of NumberOfPersons) {
            item.labelFrom = t('MANAGER.COURSE.DIALOG.NUMBER_OF_PERSONS_LABEL_FROM');
            item.labelTo = t('MANAGER.COURSE.DIALOG.NUMBER_OF_PERSONS_LABEL_TO');
        }
        for (const item of CutOffTimes) {
            item.labelFrom = t('MANAGER.COURSE.DIALOG.CUT_OFF_TIME_LABLE_FROM');
            item.labelTo = t('MANAGER.COURSE.DIALOG.CUT_OFF_TIME_LABLE_TO');
        }
        if (data !== null) {
            //console.log("datServiceDaysa",data);
            
            for (const item of ServiceDays) {
                item.text=t(`MANAGER.COURSE.DIALOG.SERVICE_DAY_ITEMS.${item.code}`);
            }       
            const id=(data.id==null?0:data.id);
            toretaApi.getShopSettingCourseDetail(id).then( (courses)=> {   
                setListRangControl(DefaultListRangControl);
                console.log('getShopSettingCourseDetail');
                var StartTimes = generateTimeArray();
                courses.course_name= data.course_name;
                courses.price= data.price;
                setForm(courses);

                //NumberOfPersons
                NumberOfPersons[0].valueFrom = courses.numberOfPersonsMin;
                NumberOfPersons[0].valueTo = courses.numberOfPersonsMax;
                ListRangControl.NumberOfPersons = NumberOfPersons;

                //Seat Type
                if(courses.seatTypes != null){
                    SeatTypes = updateCheckedStatus(SeatTypes,courses.seatTypes);
                }
                ListRangControl.SeatTypes = SeatTypes;

                //Start TimesServicePeriod
                if(courses.startTimes!=null){
                    StartTimes = updateCheckedStatus(StartTimes,courses.startTimes);
                }
                ListRangControl.StartTimes = StartTimes;
                //Service Period
               
                ServicePeriod[0].valueFrom = courses.servicePeriodFrom;
                ServicePeriod[0].valueTo = courses.servicePeriodTo;
                ListRangControl.ServicePeriod = ServicePeriod;
                //Exception
                if(courses.exceptions!=null){
                    ExceptionList = updateDateRange(ExceptionList, courses.exceptions);
                }
                //console.log(ExceptionList);
                listRangControl.ExceptionList = ExceptionList;

                //Service Days
                if(courses.serviceDays!=null){
                    ServiceDays= updateCheckedStatus(ServiceDays,courses.serviceDays);
                }
                ListRangControl.ServiceDays  = ServiceDays;

                //console.log(courses);
                //CutOffTimes
                CutOffTimes[0].valueFrom = courses.cutoffTimeFrom;
                CutOffTimes[0].valueTo = courses.cutoffTimeTo;
                ListRangControl.CutOffTimes = CutOffTimes;
                //Authorization Hold Amount

                setEditable(true);

                setListRangControl(ListRangControl);

            });
        }
        if(listRangControl.SeatTypes.length==0){
            setListRangControl(ListRangControl);
        }
        // eslint-disable-next-line
    }, [data]);

    const setDisplaySeats=()=>{
        seatTypeLanguage["none"]="MANAGER.COURSE.SEAT_TYPE.ITEM_NO_PREFERENCE";
        seatTypeLanguage["counter"]="MANAGER.COURSE.SEAT_TYPE.ITEM_COUNTER_SEAT";
        seatTypeLanguage["private"]="MANAGER.COURSE.SEAT_TYPE.ITEM_PRIVATE_ROOM";
        seatTypeLanguage["table"]="MANAGER.COURSE.SEAT_TYPE.ITEM_TABLE_SEAT";
        seatTypeLanguage["outdoor_seat"]="MANAGER.COURSE.SEAT_TYPE.ITEM_OUTDOOR_SEAT";
        seatTypeLanguage["tatami_seat"]="MANAGER.COURSE.SEAT_TYPE.ITEM_TATAMI_SEAT"; 
        seatTypeLanguage["bar_counter"]="MANAGER.COURSE.SEAT_TYPE.ITEM_BAR_COUNTER"; 
        seatTypeLanguage["takeaway"]="MANAGER.COURSE.SEAT_TYPE.ITEM_TAKEAWAY"; 
        seatTypeLanguage["semi_private"]="MANAGER.COURSE.SEAT_TYPE.ITEM_SEMI_PRIVATE"; 
        seatTypeLanguage["sofa_seat"]="MANAGER.COURSE.SEAT_TYPE.ITEM_SOFA_SEAT"; 
        seatTypeLanguage["horigotatsu"]="MANAGER.COURSE.SEAT_TYPE.ITEM_HORIGOTATSU";         
    }
    const updateData = () => {
        //TODO: ValidateEmail
        const IsBlank = (value: string) => {
            return !value || value === undefined || value === "";
        };

        /*
        if(IsBlank(form.course_name_new)){
            Swal.fire({
                icon: "error",
                title: t("MANAGER.COURSE.DIALOG.ERROR_REQUIRED_COURSE_NAME"),
            });
            return;
        }
        */

        //Number of Persons
        const NumberOfPersons = listRangControl.NumberOfPersons;
        if(isNullOrEmpty(NumberOfPersons[0].valueFrom)  && !isNullOrEmpty(NumberOfPersons[0].valueTo) )
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        else if(!isNullOrEmpty(NumberOfPersons[0].valueFrom) && isNullOrEmpty(NumberOfPersons[0].valueTo))
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        else if(!isNullOrEmpty(NumberOfPersons[0].valueFrom)  && !isNullOrEmpty(NumberOfPersons[0].valueTo)){
            var valueFrom = NumberOfPersons[0].valueFrom;
            var valueTo = NumberOfPersons[0].valueTo;
            if(valueFrom!=null && valueTo!=null && valueFrom > valueTo ){
                showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
                return;
            }
        }

        form.numberOfPersonsMin = NumberOfPersons[0].valueFrom || null;
        form.numberOfPersonsMax = NumberOfPersons[0].valueTo || null;

        const successAction = () => {
            Swal.fire({
                icon: "success",
                title: t("MANAGER.MESSAGE.UPDATE_SUCCESS")
            });
            var courseDetail =settingCourselist.find(f=>f.course_id==data?.course_id);
            if(courseDetail!=null){
                courseDetail.course_name_new = form.course_name_new;
            }
            close();
        }; 

        form.seatTypes = joinCodes(listRangControl.SeatTypes);
        form.startTimes = joinCodes(listRangControl.StartTimes);

        //Service Period
        const ServicePeriod = listRangControl.ServicePeriod;
        if(isNullOrEmpty(ServicePeriod[0].valueFrom)  && !isNullOrEmpty(ServicePeriod[0].valueTo) )
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        else if(!isNullOrEmpty(ServicePeriod[0].valueFrom) && isNullOrEmpty(ServicePeriod[0].valueTo))
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        else if(!isNullOrEmpty(ServicePeriod[0].valueFrom)  && !isNullOrEmpty(ServicePeriod[0].valueTo)){
            var valueFrom_ServicePeriod = ServicePeriod[0].valueFrom;
            var valueTo_ServicePeriod = ServicePeriod[0].valueTo;
            if(valueFrom_ServicePeriod!=null && valueTo_ServicePeriod!=null && valueFrom_ServicePeriod>valueTo_ServicePeriod){
                showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
                return;
            }
        }
        
        form.servicePeriodFrom = listRangControl.ServicePeriod[0].valueFrom;
        form.servicePeriodTo = listRangControl.ServicePeriod[0].valueTo;

        //Exception
        if(listRangControl.ExceptionList.find(f=>isNullOrEmpty(f.valueFrom) && !isNullOrEmpty(f.valueTo))!=null){
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        if(listRangControl.ExceptionList.find(f=>!isNullOrEmpty(f.valueFrom) && isNullOrEmpty(f.valueTo))!=null){
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        if(listRangControl.ExceptionList.find(f=>!isNullOrEmpty(f.valueFrom) && !isNullOrEmpty(f.valueTo) && f.valueFrom!=null && f.valueTo !=null &&  f.valueFrom > f.valueTo)!=null){
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        console.log(listRangControl.ExceptionList);
        form.exceptions = listRangControl.ExceptionList.filter(f=>f.valueFrom!=null && f.valueTo !=null).map((item)=> {return { fromDate :item.valueFrom, toDate: item.valueTo};});
         
        //serviceDays
        form.serviceDays = joinCodes(listRangControl.ServiceDays);

        //CutOffTimes
        const CutOffTimes= listRangControl.CutOffTimes;
        if(isNullOrEmpty(CutOffTimes[0].valueFrom)  && !isNullOrEmpty(CutOffTimes[0].valueTo) )
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
        else if(!isNullOrEmpty(CutOffTimes[0].valueFrom) && isNullOrEmpty(CutOffTimes[0].valueTo))
        {
            showMessage("MANAGER.COURSE.DIALOG.ERROR_CONTENT_INCORRECT");
            return;
        }
          
        form.cutoffTimeFrom = (CutOffTimes[0].valueFrom);
        form.cutoffTimeTo = (CutOffTimes[0].valueTo);
        form.authorizationHoldAmount = null;
        //delete form["file"];
        const sendform = { ...form };
        //delete sendform["file"];
        //console.log(sendform);
        //console.log(sendform);
        toretaApi.updateShopSettingCourseDetail(sendform, form.file ?? null,successAction);
        
    };
    const fileChange =async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            const file = event.target.files[0];
            setForm({ ...form, file: file});
        }
    };
    const onImageUrlChange=(imageDataUrl:string)=>{
        setEditable(false);
    }
    const onResizeDone=(file:any)=>{
        //console.log("onResizeDone");
        setForm({ ...form, file: file});
        setEditable(true);
    }
    const onResizeCancel=()=>{
        setEditable(true);
        setForm({ ...form, file: null});
    }
    const onFormatCurrencyAmt=(price:number)=>{
        if(restaurentItem!=null){
            return formatCurrencyAmt(restaurentItem.country, restaurentItem.currency, form.price);
        }
        return formatCurrencyAmt('jp','JPY',price);
    }
    const onDateChange=()=>{

    }
    const onEditable=()=>{
        return editable;
    }
    const closeDialog=()=>{
        setForm(defaultValue);
        setListRangControl(ListRangControl);
        close(); 
    }
    const pickerToShortTime=(timestamp: number|null)=> {
        if(timestamp == null)return null;
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const totalSeconds = hours * 3600 + minutes * 60;
        return totalSeconds;
    }
    const shortTimeToPicker=(timestamp: number|null)=>{
        if(timestamp==null) return null;
        var date = moment().startOf('day').add(timestamp,'seconds');
        return date.valueOf(); 
    }
    const showMessage=(messageId:string)=>{
        Swal.fire({
            icon: "error",
            title: t(messageId),
        });
    }
    const isNullOrEmpty=(str:any)=>{
        if(str==null) return true;
        return str=="";
    }
    const twoByteCharRegex = /[^\x00-\x7F]/g;
    function handleCourseNameChange(str: string, totalByte:number = 80): string {

        // Nếu độ dài chuỗi nhỏ hơn một nửa số byte giới hạn, trả về chuỗi gốc
        if(str.length < totalByte / 2) return str;

        let result = ''; 
        let currentByteLength = 0;
        for (let i = 0; i < str.length; i++) {
            const char = str[i];
            
            const isTwoByteChar = twoByteCharRegex.test(char);
            let charLength = isTwoByteChar ? 2 : 1;
            twoByteCharRegex.lastIndex = 0;
            if (currentByteLength + charLength <= totalByte) {
              result += char;
              currentByteLength += charLength;
            } else {
                // Đã đạt độ dài tối đa, không thêm ký tự nữa
                return result;
            }
        }
        return result;
      }
    return (
        <Box>
            <Dialog open={open} className="paymentDialogWidth" fullWidth={true} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    {eval(t("MANAGER.COURSE.DIALOG.TITLE"))}
                </DialogTitle>
                <DialogContent>
                    <Box p={1} className="clear-padding">
                        <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.PRICE")}
                                </Box>
                            </Typography>
                        <div className={classes.coursePrice}>{onFormatCurrencyAmt(form.price)}</div>
                    </Box>
                            <Box p={1} className={classes.courseLabel +" clear-padding "+classes.groupPd30}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.APP_NAME")}
                                </Box>
                            </Typography>
                            <TextField
                                label=""
                                variant="outlined"
                                type="text"  
                                value={form.course_name_new}
                                inputProps={{ maxLength: 80}}
                                fullWidth
                                multiline
                                rows={3}
                                disabled={!editable}
                                onChange={(event) => {
                                    setForm({ ...form, course_name_new: handleCourseNameChange(event.target.value) });
                                }}
                                placeholder={t("MANAGER.COURSE.DIALOG.NAME_NOTE")}
                            />
                    </Box>
                    <Box p={1} className={classes.groupPd30  +" clear-padding"}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.IMAGE")}
                                </Box>
                            </Typography>
                        <TextField
                            label=""
                            type="text"
                            value={form.image}
                            fullWidth
                            aria-readonly={true}
                            disabled={!editable}
                            placeholder={t("MANAGER.COURSE.DIALOG.IMAGE_NOTE")}
                        />
                    </Box>
                    
                    <Box className="courseImage">
                        <CourseImageCrop onImageUrlChange={onImageUrlChange} onDone={onResizeDone} onCancel={onResizeCancel}/>
                    </Box>
                        <Box p={1} className={classes.groupPd15 +" clear-padding"}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.DESCRIPTION")}
                                </Box>
                            </Typography>
                            <TextField
                                label=""
                                type="text"
                                multiline
                                rows={4}
                                variant="outlined"
                                value={form.description}
                                inputProps={{ maxLength: 800}}
                                fullWidth
                                disabled={!editable}
                                onChange={(event) => {
                                    setForm({ ...form, description: handleCourseNameChange(event.target.value,800) });
                                }}
                                placeholder={t("MANAGER.COURSE.DIALOG.DESCRIPTION_NOTE")}
                            />
                        </Box>

                        <Box mt={2} className={classes.groupPd30} >
                            <hr className={classes.break_line}/>
                        </Box>
                        <Box mt={2} className={classes.groupPd30} >
                            <div className={classes.required}>
                            {t("MANAGER.COURSE.DIALOG.REQUIRED_NOTE")}
                            </div>
                        </Box>

                        <Box className={classes.groupPd30} >
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.NUMBER_OF_PERSONS")}
                                </Box>
                            </Typography>
                            <div className={classes.note} color="red">{t("MANAGER.COURSE.DIALOG.NUMBER_OF_PERSONS_NOTE")}</div>
                            <Box mt={1} className="course-cotnent">
                                <InputNumbericRange onEditable={onEditable} dataList={listRangControl.NumberOfPersons}/>
                            </Box>
                        </Box>

                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.SEAT_TYPE")}
                                </Box>
                            </Typography>
                            <div className={classes.note}> {t("MANAGER.COURSE.DIALOG.SEAT_TYPE_NOTE")}</div>
                            <div className="course-cotnent seats-type-list">
                                <InputChecklistRange onEditable={onEditable} dataList={ listRangControl.SeatTypes}/>
                            </div> 
                       </Box>

                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.START_TIME")}
                                </Box>
                            </Typography>
                            <div className={classes.note}>{t("MANAGER.COURSE.DIALOG.START_TIME_NOTE")}</div>
                            <div className="course-cotnent">
                                <InputChecklistRange onEditable={onEditable} dataList={ listRangControl.StartTimes}/>
                            </div>
                        </Box>
                         {/*               
                        <Box className={classes.group}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" m={0}>
                                {t("MANAGER.COURSE.DIALOG.SERVICE_PERIOD")}
                                </Box>
                            </Typography>
                            <div> {t("MANAGER.COURSE.DIALOG.SERVICE_PERIOD_NOTE")}</div>
                            <InputDateRange onEditable={onEditable} dataList={listRangControl.ServicePeriod}/>
                        </Box>
                            */}
                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.EXCEPTION")}
                                </Box>
                            </Typography>
                            <div className={classes.note}> {t("MANAGER.COURSE.DIALOG.EXCEPTION_NOTE")}</div>
                            <div className="course-cotnent">
                                <InputDateRange onEditable={onEditable} dataList={listRangControl.ExceptionList}/>
                            </div>
                        </Box>

                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" className={classes.groupTile} m={0}>
                                {t("MANAGER.COURSE.DIALOG.SERVICE_DAYS")}
                                </Box>
                            </Typography>
                            <div className={classes.note}>{t("MANAGER.COURSE.DIALOG.SERVICE_DAYS_NOTE")}</div>
                            <div className="course-cotnent">
                                <InputChecklistRange onEditable={onEditable} dataList={ listRangControl.ServiceDays}/>
                            </div>
                        </Box>

                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" m={0} className={classes.groupTile} >
                                {t("MANAGER.COURSE.DIALOG.CUT_OFF_TIME")}
                                </Box>
                            </Typography>
                            <div className={classes.note}> {t("MANAGER.COURSE.DIALOG.CUT_OFF_TIME_NOTE")}</div>
                            <div className="course-cotnent">
                                <InputTimeDropdownRange onEditable={onEditable} fixWidth={cutoffTimeWidth} dataList={listRangControl.CutOffTimes} listDateType={CutOffTimeDaysDropdownItems}  />
                            </div>
                        </Box>
                        {/*        
                        <Box className={classes.groupPd40}>
                            <Typography variant="body1" component="h1" gutterBottom>
                                <Box fontWeight="fontWeightMedium" m={0} className={classes.groupTile} >
                                {t("MANAGER.COURSE.DIALOG.AUT_HOLD_AMOUNT")}
                                </Box>
                            </Typography>
                            <div className={classes.note}> {t("MANAGER.COURSE.DIALOG.AUT_HOLD_AMOUNT_NOTE")}</div>
                            <div className={classes.requiredAuth}> {t("MANAGER.COURSE.DIALOG.AUT_HOLD_AMOUNT_NOTE_REQUIRED")}</div>
                            <Box mt={1} width={'100%'} className="course-cotnent">
                                <table>
                                    <tr>
                                        <td>{getCurrency(restaurentItem?restaurentItem.currency:"")}</td><td width={150}>
                                        <TextField
                                        fullWidth
                                        value={form.authorizationHoldAmount}
                                        inputProps={{ inputProps: { min: 0, max: 999999 ,maxLength: 6 } }}
                                        InputProps={{ inputProps: { min: 0, max: 999999 ,maxLength: 6 } }}
                                        type="number"  
                                        aria-readonly={true}
                                        disabled={!editable} 
                                        onChange={(event) => {
                                            setForm({ ...form, authorizationHoldAmount:parseInt(event.target.value.slice(0, 6)) });
                                        }}
                                        />
                                        </td>
                                    </tr>
                                </table>
                            </Box>
                        </Box>*/}
                </DialogContent>
                <DialogActions>
                    {editable?(<>
                    <Button onClick={closeDialog} color="secondary" >
                        {t("MANAGER.COURSE.ACTION.CANCEL")}
                    </Button>
                    <Button onClick={updateData} color="primary">
                        {t("MANAGER.COURSE.ACTION.SAVE")}
                    </Button></>
                    ):(<>
                    <Button  color="secondary"  disabled={true}>
                    {t("MANAGER.COURSE.ACTION.CANCEL")}
                    </Button>
                    <Button  color="primary"  disabled={true}>
                    {t("MANAGER.COURSE.ACTION.SAVE")}
                    </Button></>
                    )
                    }
                </DialogActions>
            </Dialog>
        </Box>
    );
};
export default FormDialog;
