import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Button,
    Grid,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Typography,
    TextField,
} from "@material-ui/core";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import { DatePicker, GreenButton } from "../../Components";
import CountrySelect, { CountryList } from "./CountrySelectField";
import basecountry from "../../country.json";
import ToretaApi from "../../ToretaApi";
import {
    ShopDetail,
    YoyakuBody,
    ToretaReservationSearchBody,
    ToretaReservationsDaysResponse,
    ShopInfo,
    Course,
    Table,
    PersonalInfo, 
    PersonalInfoError,
} from "../../common/interface";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();
type FormProps = {
    open: boolean;
    data: any;
    close: ()=> void;
};

interface CanSelectTable extends Table {
    disabled: boolean;
}

interface DailogPersonalInfo extends PersonalInfo {
    //phonecode: string;
    phoneiso: string;
}

interface DailogPersonalInfoError extends PersonalInfoError {
    table_category: boolean;
    date: boolean;
    time: boolean;
    seats: boolean;
    course: boolean;
}

const initValue: YoyakuBody = {
    adults: 0,
    children: 0,
    target_date: null,
    start_time: null,
    seats: 0,
    table_category: null,
    title: "Mr",
    last_name: "",
    first_name: "",
    last_name_reading: "",
    first_name_reading: "",
    country_code: "",
    phone: "",
    email: "",
    courses: [],
    note: "",
    optin_dm: true,
    language:"",
    cancellationPolicy:"",
    email_template:""
};

const FormDialog: React.FunctionComponent<FormProps> = ({ open , data , close }) => {
    const { t } = useTranslation();

    const toretaApi = ToretaApi();
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const shop: ShopDetail = useSelector((state: any) => state.shop);
    const shopinfo: ShopInfo = useSelector((state: any) => state.shopinfo);

    const [
        seatInfos,
        setSeatInfos,
    ] = React.useState<ToretaReservationsDaysResponse>({});
    var seatTypeLanguage: { [id: string] : string; } = {};
    const [form, setForm] = React.useState(initValue);

    const usedCourses = React.useMemo(() => {
        if (!shopinfo.courses) {
            return [];
        }
        if (!form.target_date) {
            return [];
        }
        const isInExcludeDate = (course: Course) => {
            //是否隱藏餐點
            try {
                const today = form.target_date;
                const totime = form.start_time;
                if (!today) { //未指定入席日
                    return true;
                }
                if (!totime) { //未指定入席時間
                    return true;
                }

                //add enable_lunch/enable_dinner filter
                const m = moment( today );
                const ohours = shopinfo.openhours? shopinfo.openhours: [];
                //console.log(today , m.weekday() , ohours[m.weekday()] );

                if( 
                    totime >= (ohours[m.weekday()].lunch_start ?? 86400) && 
                    totime <= (ohours[m.weekday()].lunch_end ?? 0 ) 
                ){  
                    if(course.enable_lunch !== true){
                        console.log('excluded L:', course.name);
                        //lunch time & course is not lunch
                        return true;
                    }
                }
                
                if( 
                    totime >= (ohours[m.weekday()].dinner_start ?? 86400) && 
                    totime <= (ohours[m.weekday()].dinner_end ?? 0 ) 
                ){
                    if(course.enable_dinner !== true){
                        console.log('excluded D:', course.name);
                        // dinner time , course is not dinner
                        return true;
                    }
                }
                //[非供餐期間]的檢查
                if (
                    course.exclude_date_from === null &&
                    course.exclude_date_to === null
                ) {
                    //console.log('included 1:', course.name);
                    return false;//沒有[非供餐期間]的設定-->顯示餐點
                } else if (
                    course.exclude_date_from === null &&
                    course.exclude_date_to &&
                    course.exclude_date_to >= today
                ) {
                    //console.log('excluded 1:', course.name);
                    return true; //[非供餐期間]截止日 晚於指定日期 (無起始日)-->隱藏餐點
                } else if (
                    course.exclude_date_to === null &&
                    course.exclude_date_from &&
                    course.exclude_date_from <= today
                ) {
                    console.log('excluded 2:', course.name);
                    return true;//[非供餐期間]起始日 早於指定日期 (無截止日)-->隱藏餐點
                } else if (course.exclude_date_from && course.exclude_date_to) {
                    if (
                        course.exclude_date_from <= today &&
                        course.exclude_date_to >= today
                    ) {
                        //console.log('excluded 3:', course.name);
                        return true; //指定日期落在停止供餐期間-->隱藏餐點
                    }else{
                        return false; //指定日期不在停止供餐期間-->顯示餐點
                    }
                }
            } catch (e) {
                //console.error(e);
            }
            //console.log('included 2:', course.name);
            return true; //預設隱藏餐點
        };

        const validCourse = (course: Course) => {
            //console.log("validCourse check:", course);
            const today = form.target_date;
            if (!today) {
                return false;//無指定入席日則不顯示餐點清單
            }
            
            if (shop.onCourse && !shop.onCourse.includes(course.id)) {
                return false;// 如果餐點不在允許清單內就排除之
            }

            if (course.start_date === null && course.end_date === null) {
                return !isInExcludeDate(course);//餐點無設定供應期間, 則以午晚餐+停止供餐設定判斷
            } else if (course.end_date === null) {
                if (course.start_date && course.start_date <= today) {
                    return !isInExcludeDate(course);//若餐點有起始日期, 則以午晚餐+停止供餐設定判斷
                }
            } else {
                if (
                    course.start_date &&
                    course.end_date &&
                    today >= course.start_date &&
                    today <= course.end_date
                ) {
                    return !isInExcludeDate(course);//若餐點落在供應期間, 則還要加上午晚餐+停止供餐設定判斷
                }
            }
            //console.log('excluded by start end date:', course.name);
            return false;
        };

        //console.log("shopInfo",shopinfo); //餐廳資訊
        //console.log("onCourse",shop.onCourse); //餐點的允許清單

        return shopinfo.courses.filter(validCourse);
    }, [shopinfo, shop, form]);

    const seatRefresh = async (newvalue: YoyakuBody) => {
        //console.log("seatRefresh");
        const start_date = moment()
            .add(shop.accept_limit_day, "days")
            .format("YYYY-MM-DD");
        const end_date = moment()
            .add(shop.allowed_reservation_days, "days")
            .format("YYYY-MM-DD");
        const querydata: ToretaReservationSearchBody = {
            id: "",
            start_date: start_date,
            end_date: end_date,
            seats: newvalue.adults + newvalue.children,
        };

        await toretaApi.GetEmptySetas(shop.public_key , querydata).then((info) => {
            let match = false;
            if (info.slots) {
                if (newvalue.target_date) {
                    const dateslot = info.slots.find(
                        (a: any) => a.date === newvalue.target_date
                    );
                    if (dateslot) {
                        const timeseat = dateslot.slots.find(
                            (a: any) => a.start_time === newvalue.start_time
                        );
                        if (timeseat) {
                            if (newvalue.table_category !== null) {
                                const tablecategories = timeseat?.options[0].table_categories.find(
                                    (a: any) => a === newvalue.table_category
                                );
                                if (tablecategories) {
                                    match = true;
                                }
                            } else {
                                match = true;
                            }
                        }
                    }
                }
                if (!match) {
                    newvalue.start_time = null;
                    newvalue.table_category = null;
                    if (info.slots[0]) {
                        newvalue.target_date = info.slots[0].date;
                    }
                }
            }
            setSeatInfos(info);
        });
        setForm(newvalue);
    };
    const handleNumberChangeAndCallSeats = (key: string) => {
        
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = {
                ...form,
                [key]: event.target.value as number,
                start_time: null,
                table_category: null,
                courses: [{ id: "", count: 0 }],
            };

            await seatRefresh(newvalue);
        };
    };
    const handleTextChange = (key: string) => (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const newvalue = { ...form, [key]: event.target.value as string };
        setForm(newvalue);
    };
    const regexReading = new RegExp('^[ぁ-ゔァ-ヴーｧ-ﾝﾞﾟ々〆〤ヶａ-ｚＡ-Ｚa-zA-Z\\s]*$');
    const handlePersonalInfoTextChange = (key: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        if(key === "last_name_reading" || key === "first_name_reading"){
            if(event.target.value && !regexReading.test(event.target.value)){
                event.target.value = personalInfo[key];
            }
        }
        const newvalue = { ...personalInfo, [key]: event.target.value as string };
        setPersonalInfo(newvalue);
    };    
    const handleSelectTextChange = (key: string) => {
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = { ...form, [key]: event.target.value as string };
            setForm(newvalue);
        };
    };
    const selectDisabled = !seatInfos.slots || seatInfos.slots.length === 0;
 
    const finishWork = () => {

        // 整理菜單
        //props.onNext(form);
        if (personalInfo.phoneiso === null || personalInfo.phoneiso === "") {
            return;
        }
        const country = basecountry.find((a) => a.iso === shop.country.toUpperCase());
        let country_code = "";
        const number = phoneUtil.parseAndKeepRawInput(personalInfo.phone, personalInfo.phoneiso);
        const phone = phoneUtil.format(number, PhoneNumberFormat.E164);
        if (number && number.getCountryCode()) {
            const tempcode = number.getCountryCode();
            if (tempcode) {
                country_code = `${tempcode}`;
            }
        }
        let value: PersonalInfo = {
            ...personalInfo,
            phone: personalInfo.phone,
            country_code: country_code,
            phoneview: phone,
        };
        //console.log(number,number.getCountryCode());
        if (country) {
            if (country.iso === personalInfo.phoneiso) {
                value.phoneview = phoneUtil.format(number, PhoneNumberFormat.NATIONAL).replaceAll(/ /g, "");
            }
        }
        form.country_code = value.country_code;
        form.phone = value.phone;
        form.title = value.title;//TODO: check more details?
        form.email = shop.email; //TODO: replace by customer email in pay flow?
        form.last_name = value.last_name;
        form.first_name = value.first_name;
        form.last_name_reading = value.last_name_reading;
        form.first_name_reading = value.first_name_reading;

        
        form.language = currentLanguage;
        //console.log("FormDialog.finishWork",form);
        toretaApi.AddReserveByStaff(
            shop.public_key,
            form,
            () => { //next action
                //console.log("FormDialog: clear form after sms sent.");
                DoClose();
            },
            () => { //fail action
                //console.log("FormDialog: fail action to do.");
            },
        );

    };
    const DoClose= () => {
        setForm(initValue);
        setPersonalInfo(cretaInitValue());
        close();
    };
    const check = () => {
        const currentError = {
            table_category: false,
            date: false,
            time: false,
            seats: false,
            course: false,
            last_name: false,
            first_name: false,
            phone: false,
            email: false,
            phonecode: false,
        };
        if(form.seats===0){
            currentError.seats = true;
        }
        if (!form.target_date || form.target_date==="") {
            currentError.date = true;
        }
        if (!form.start_time || form.start_time === 0 ) {
            currentError.time = true;
        }
        if(!form.table_category || form.table_category===""){
            currentError.table_category = true;
        }
        if (shop.country === "tw" || shop.country === "jp") {
            if(personalInfo.last_name===""){
                currentError.last_name = true;
            }
        }else{
            if(personalInfo.first_name===""){
                currentError.first_name = true;
            }      
        }
        //currentError.phonecode = true;
        try {
            const number = phoneUtil.parse(personalInfo.phone, personalInfo.phoneiso);
            if (!phoneUtil.isValidNumber(number)) {
                currentError.phone = true;
            }
        } catch (e) {
            currentError.phone = true;
        }
        setErrors(currentError);
    };
    const canGoNext = () => {
        
        //console.log('canGoNext',form,errors);

        // 處理結束時間
        const endinfo = getTableEndtime();
        if (endinfo) {
            if (endinfo.has_limit_time) {
                form.end_time = endinfo.end_time;
            } else {
                delete form.end_time;
            }
        }
        const tempcourse: any = {};
        if (form.courses) {
            form.courses.forEach((course) => {
                if (tempcourse[course.id]) {
                    tempcourse[course.id] += course.count;
                } else {
                    tempcourse[course.id] = course.count;
                }
            });
            const courselist = [];
            for (let key in tempcourse) {
                courselist.push({ id: key, count: tempcourse[key] });
            }
            if (courselist.length <= 0) {
                courselist.push({ id: "", count: 0 });
            }
            form.courses = courselist;
        }
        //console.log("canGoNext",form,personalInfo);
        try {
            form.seats = form.adults + form.children;

            if(form.seats < shop.restaurant_seats_min || form.seats > shop.restaurant_seats_max){
                return false;
            }
            if (form.adults + form.children === 0) {
                return false;
            }
            if (!form.target_date) {
                return false;
            }
            if (!form.start_time) {
                return false;
            }
            if (shop.needTable) {
                if (!form.table_category || form.table_category === "") {
                    return false;
                }
            }
            if (
                form.courses &&
                form.courses.filter((a) => a.id !== "" && isNaN(a.count)).length > 0
            ) {
                return false;
            }
            if (
                form.courses &&
                form.courses.filter((a) => a.id !== "" && a.count <= 0).length > 0
            ) {
                return false;
            }
            if (shop.needCourse) {
                if (
                    !form.courses ||
                    form.courses.filter((a) => a.id !== "").length === 0
                ) {
                    return false;
                }
            }
            if (shop.country === "tw" || shop.country === "jp") {
                if (personalInfo.last_name === "") {
                    return false;
                }
            }else{
                if (personalInfo.first_name === "") {
                    return false;
                }    
            }
            if (personalInfo.phone === "") {
                return false;
            }
            if (!personalInfo.phoneiso) {
                return false;
            }
            if (personalInfo.phoneiso === "") {
                return false;
            }
            if (errors.phone) {
                return false;
            }
        } catch (e) {
            return false;
        }
        return true;
    };
    const getTableEndtime = () => {
        try {
            if (seatInfos.slots) {
                const timetable = seatInfos.slots
                    .find((a) => a.date === form.target_date)
                    ?.slots.find((a) => a.start_time === form.start_time);
                if (timetable) {
                    if(form.table_category == null){
                        return timetable.options[0];
                    }
                    return timetable.options.find(o=>o.table_categories.includes(form.table_category||""));
                }
            }
            return null;
        } catch (e) {
            return null;
        }
    };
    const getTableCategories = (): CanSelectTable[] => {
        let result: string[] | undefined = [];
        try {
            if (seatInfos.slots) {
                const timetable = seatInfos.slots
                    .find((a) => a.date === form.target_date)
                    ?.slots.find((a) => a.start_time === form.start_time);
                if (timetable) {
                    timetable.options.forEach(option => {
                        result= result?.concat(option.table_categories);
                    });
                }
            }

            const usedTable = shopinfo.tables?.map((table) => {
                const disabled = !(result && result?.includes(table.key_name));
                return {
                    ...table,
                    disabled,
                };
            });
            return usedTable || [];
        } catch (e) {
            const usedTable = shopinfo.tables?.map((table) => {
                return {
                    ...table,
                    disabled: true,
                };
            });
            return usedTable || [];
        }
    };
    const addCourse = () => {
        form.courses?.push({ id: "", count: 0 });
        setForm({ ...form });
    };
    // eslint-disable-next-line
    const canSelectTableCategories = React.useMemo(() => getTableCategories(), [
        seatInfos,
        form,
        getTableCategories,
    ]);
    // eslint-disable-next-line
    const canSelectDays = React.useMemo(() => {
        return seatInfos.slots?.map((a) => a.date);
        // eslint-disable-next-line
    }, [seatInfos, form]);
    const showTable = shop.needTable;
    const showMenu = shop.needCourse || shop.needCourse ===null ;
    const firstPeopleCount = !shop.accept_children
        ? t("PARTY_SIZE")
        : t("ADULTS");
    const seats = form.adults + form.children;
 
    const country = shop.country ? shop.country.toUpperCase() : null;
    const cretaInitValue = () => {
        let defaultISO = CountryList.find((a) => a.iso === country)?.iso;
        //console.log("cretaInitValue:",country, defaultISO);

        return {
            last_name: "",
            first_name: "",
            phone: "",
            email: "",
            phoneiso: defaultISO,
            title: "Mr",
        } as DailogPersonalInfo;
    };
    const [personalInfo, setPersonalInfo] = React.useState<DailogPersonalInfo>(
        cretaInitValue()
    );
    const [errors, setErrors] = React.useState<DailogPersonalInfoError>({
        table_category: true,
        date: false,
        time: true,
        seats: true,
        course: true,
        last_name: false,
        first_name: false,
        phone: true,
        email: false,
        phonecode: false,
    });
    const handleSelectChange = (key: string) => {
        return async (event: React.ChangeEvent<{ value: unknown }>) => {
            const newvalue = { ...personalInfo, [key]: event.target.value as string };
            setPersonalInfo(newvalue);
        };
    };
    const handleCountrySelectChange = (value: string) => {
        const newvalue: DailogPersonalInfo = { ...personalInfo, phoneiso: value };
        setPersonalInfo(newvalue);
    };
    const setDisplySeats=()=>{
        seatTypeLanguage["none"]="MANAGER.ITEMS.ITEM_NO_PREFERENCE";
        seatTypeLanguage["counter"]="MANAGER.ITEMS.ITEM_COUNTER_SEAT";
        seatTypeLanguage["private"]="MANAGER.ITEMS.ITEM_PRIVATE_ROOM";
        seatTypeLanguage["table"]="MANAGER.ITEMS.ITEM_TABLE_SEAT";
        seatTypeLanguage["outdoor_seat"]="MANAGER.ITEMS.ITEM_OUTDOOR_SEAT";
    }
    const getDisplySeats=(key:any, defV:string)=>{
        try{
        var lang= t(seatTypeLanguage[key]);
        if(lang=="" || lang==null){
            setDisplySeats();
            lang= t(seatTypeLanguage[key]);
        }
        if(lang!=undefined && lang!=null && lang!="" &&lang.length>0) return lang;
        return defV;
        }catch{
            return defV; 
        }
    }
    useEffect(() => {
        setDisplySeats();
        // toretaApi.ScrollTop();
        if (data !== null) {
            //console.log("FormDialog, data:",data);
            //setShopDefault({ ...defaultValue, ...data });
            //toretaApi.GetShopDetail(data.public_key);
            toretaApi.GetShopOther(data.public_key);
            //form.country_code = data.country_code;
            //setForm({...form , country_code : data.country});
            
            setPersonalInfo({...personalInfo ,phoneiso: data.country.toUpperCase() });
            if (form.adults + form.children > 0) {
                seatRefresh(form);
            }
        }
        // eslint-disable-next-line
    }, [data]);

    useEffect(() => {
        check();
        // eslint-disable-next-line
    }, [form,personalInfo]);

    let nameField = (
        <>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label={t("FIRST_NAME")}
                    rows={4}
                    defaultValue={personalInfo.first_name}
                    variant="outlined"
                    error={errors.first_name}
                    onChange={handlePersonalInfoTextChange("first_name")}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label={t("LAST_NAME")}
                    rows={4}
                    defaultValue={personalInfo.last_name}
                    variant="outlined"
                    onChange={handlePersonalInfoTextChange("last_name")}
                />
            </Grid>
        </>
    );
    if (shop.country === "tw") {
        nameField = (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("LAST_NAME")}
                        error={errors.last_name}
                        rows={4}
                        defaultValue={personalInfo.last_name}
                        variant="outlined"
                        onChange={handlePersonalInfoTextChange("last_name")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("FIRST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.first_name}
                        variant="outlined"
                        onChange={handlePersonalInfoTextChange("first_name")}
                    />
                </Grid>
            </>
        );
    }
    let nameFieldReading = (<></>);
    if (shop.country === "jp") {
        nameField = (
            <>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("LAST_NAME")}
                        error={errors.last_name}
                        rows={4}
                        defaultValue={personalInfo.last_name}
                        variant="outlined"
                        onChange={handlePersonalInfoTextChange("last_name")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label={t("FIRST_NAME")}
                        rows={4}
                        defaultValue={personalInfo.first_name}
                        variant="outlined"
                        onChange={handlePersonalInfoTextChange("first_name")}
                    />
                </Grid>
            </>
        );
        if(shop.company_country === "jp" && currentLanguage === "ja"){
            nameFieldReading = (
                <>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t("LAST_NAME_READING")}
                            rows={4}
                            defaultValue={personalInfo.last_name_reading}
                            variant="outlined"
                            onChange={handlePersonalInfoTextChange("last_name_reading")}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label={t("FIRST_NAME_READING")}
                            rows={4}
                            defaultValue={personalInfo.first_name_reading}
                            variant="outlined"
                            onChange={handlePersonalInfoTextChange("first_name_reading")}
                        />
                    </Grid>
                </>
            );
        }
    }
    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{shop.name} {t("MANAGER.ACTION.ADD")}{t("HOME.BUTTON_TEXT")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>
                    <Grid container spacing={2}>
                <Grid
                    item
                    xs={shop.accept_children ? 6 : 12}
                    md={shop.accept_children ? 2 : 4}
                >
                    <FormControl fullWidth variant="outlined">
                        <InputLabel>{firstPeopleCount}</InputLabel>
                        <Select
                            error={errors.seats}
                            value={form.adults}
                            onChange={handleNumberChangeAndCallSeats("adults")}
                            label={firstPeopleCount}
                        >
                        {shop.restaurant_seats_max > 0 ? Array.from(
                                    Array(shop.restaurant_seats_max +1).keys()
                                ).map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={index}>
                                            {index}
                                            {t("PEOPLE")}
                                        </MenuItem>
                                    );
                                }):(<></>)}
                        </Select>
                    </FormControl>
                </Grid>
                {shop.accept_children ? (
                    <Grid item xs={6} md={2}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>{t("CHILDREN")}</InputLabel>
                            <Select
                                value={form.children}
                                onChange={handleNumberChangeAndCallSeats("children")}
                                label={t("CHILDREN")}
                            >
                            {shop.restaurant_seats_max > 0 ? Array.from(
                                    Array(shop.restaurant_seats_max +1).keys()
                                ).map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={index}>
                                            {index}
                                            {t("PEOPLE")}
                                        </MenuItem>
                                    );
                                }):(<></>)}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                        <></>
                    )}

                <Grid item xs={12} md={4}>
                    <DatePicker
                        error={errors.date}
                        disabled={selectDisabled}
                        label={t("DATE")}
                        value={form.target_date}
                        enableDays={canSelectDays}
                        onChange={(value: string) => {
                            setForm({
                                ...form,
                                target_date: value,
                                start_time: null,
                                table_category: null,
                                courses: [],
                            });
                        }}
                        inputVariant="outlined"
                    ></DatePicker>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl
                        fullWidth
                        disabled={selectDisabled}
                        variant="outlined"
                    >
                        <InputLabel>{t("TIME")}</InputLabel>
                        <Select
                            error={errors.time}
                            value={form.start_time || ""}
                            onChange={(event) => {
                                setForm({
                                    ...form,
                                    start_time: event.target.value as number,
                                    table_category: null,
                                    courses: [],
                                });
                            }}
                            label={t("TIME")}
                        >
                            {seatInfos.slots
                                ?.find((a) => a.date === form.target_date)
                                ?.slots.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={item.start_time}
                                        >
                                            {toretaApi.transformTimeNumber12h(
                                                item.start_time
                                            )}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                {showTable ? (
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>
                                {t("SELECT_TABLE_CAETGORY")}
                            </InputLabel>
                            <Select
                                error={errors.table_category}
                                value={form.table_category || ""}
                                onChange={handleSelectTextChange(
                                    "table_category"
                                )}
                                label={
                                    t("SELECT_TABLE_CAETGORY") +
                                    (shop.needTable ? t("REQUIRED") : "")
                                }
                            >
                                {canSelectTableCategories.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={item.key_name}
                                            disabled={item.disabled}
                                        >
                                            {getDisplySeats(item.key_name,item.display_name)}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                ) : (
                        <></>
                    )}
                {showMenu ? (
                    <>
                        <Grid item xs={12}>
                            
                            <Box fontWeight="fontWeightBold" m={0}>
                                {t("RESERVE_INFO.SELECT_COURSE")}
                                {shop.needCourse ? (
                                    <span 
                                    // className={classes.required}
                                    >
                                        {" "}
                                        {t("REQUIRED")}
                                    </span>
                                ) : (
                                        ""
                                    )}
                                :
                            </Box>
                        </Grid>

                        {form.courses?.map((course, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} md={9}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                {t(
                                                    "RESERVE_INFO.SELECT_COURSE"
                                                )}
                                            </InputLabel>
                                            <Select
                                                value={course.id}
                                                onChange={(event) => {
                                                    if (
                                                        form.courses &&
                                                        form.courses[index]
                                                    ) {
                                                        form.courses[
                                                            index
                                                        ].id = event.target
                                                            .value as string;
                                                        if (
                                                            form.courses[index]
                                                                .id !== ""
                                                        ) {
                                                            if (
                                                                form.courses[
                                                                    index
                                                                ].count <= 0
                                                            ) {
                                                                form.courses[
                                                                    index
                                                                ].count =
                                                                    form.adults +
                                                                    form.children;
                                                            }
                                                        } else {
                                                            form.courses[
                                                                index
                                                            ].count = 0;
                                                        }
                                                        setForm({ ...form });
                                                    }
                                                }} 
                                            >
                                                <MenuItem value={""}>
                                                    {t(
                                                        "RESERVE_INFO.SELECT_COURSE_OPTIONS"
                                                    )}
                                                </MenuItem>
                                                {usedCourses.map(
                                                    (item, index) => {
                                                        return (
                                                            <MenuItem value={item.id} style={{  whiteSpace: "normal" , wordBreak: "break-all"  }}
                                                            >
                                                                {item.name}
                                                                {item.price ? (" " + shop.currency + " " +item.price) : null}
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <FormControl fullWidth>
                                            <InputLabel>
                                                {t(
                                                    "RESERVE_INFO.SELECT_COURSE_SEATS"
                                                )}
                                            </InputLabel>
                                            <Select
                                                disabled={course.id === ""}
                                                value={course.count}
                                                onChange={(event) => {
                                                    if (
                                                        form.courses &&
                                                        form.courses[index]
                                                    ) {
                                                        form.courses[
                                                            index
                                                        ].count = event.target
                                                            .value as number;
                                                        setForm({ ...form });
                                                    }
                                                }}
                                            >
                                                {new Array(
                                                    (seats > course.count
                                                        ? seats
                                                        : course.count) + 1
                                                )
                                                    .fill(0)
                                                    .map((item, index) => {
                                                        if (
                                                            index === 0 &&
                                                            course.id !== ""
                                                        ) {
                                                            return null;
                                                        }
                                                        return (
                                                            <MenuItem value={index} >
                                                                {index}
                                                                {t(
                                                                    "RESERVE_INFO.FREE"
                                                                )}
                                                            </MenuItem>
                                                        );
                                                    })}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </React.Fragment>
                            );
                        })}
                        {shop.accept_multi_courses ? (
                            <Grid item xs={12}>
                                <FormControl>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={addCourse}
                                    >
                                        {t("RESERVE_INFO.ADD_SELECT_COURSE")}
                                    </Button>
                                </FormControl>
                            </Grid>
                        ) : null}
                    </>
                ) : (
                        <></>
                    )}
            </Grid>
                    
            <Box mt={4}>
                <Typography variant="body1" component="h2" gutterBottom>
                    <Box fontWeight="fontWeightBold" m={0}>
                        {t("REQUEST")}:
                    </Box>
                </Typography>
                {shop.note_message &&
                    shop.note_message.split("\n").map(function (item, key) {
                        return (
                            <span key={key}>
                                {item}
                                {/* <br /> */}
                            </span>
                        );
                    })}
            </Box>
            <Box mt={2}>
                <TextField
                    fullWidth
                    label=""
                    multiline
                    rows={2}
                    defaultValue={form.note}
                    variant="outlined"
                    onChange={handleTextChange("note")}
                />
            </Box>
            <br />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl variant="outlined">
                        <InputLabel>{t("PERSONAL_INFO.TITLE")}</InputLabel>
                        <Select value={personalInfo.title} onChange={handleSelectChange("title")} label={t("TITLE")}>
                            <MenuItem key={"Mr"} value={"Mr"}>
                                {t("PERSONAL_INFO.MR")}
                            </MenuItem>
                            <MenuItem key={"Mrs"} value={"Mrs"}>
                                {t("PERSONAL_INFO.MRS")}
                            </MenuItem>
                            <MenuItem key={"Ms"} value={"Ms"}>
                                {t("PERSONAL_INFO.MS")}
                            </MenuItem>
                            <MenuItem key={"Mdm"} value={"Mdm"}>
                                {t("PERSONAL_INFO.MDM")}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                {nameField}
                {nameFieldReading}
                <Grid item xs={12} sm={6}>
                    <CountrySelect value={personalInfo.phoneiso} onChange={handleCountrySelectChange}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField fullWidth rows={4} variant="outlined"
                        label={t("MOBILE")}
                        error={errors.phone}
                        defaultValue={personalInfo.phone}
                        onChange={handlePersonalInfoTextChange("phone")}
                    />
                </Grid>
                <Box mt={2} textAlign="center">
                <GreenButton disabled={!canGoNext()} variant="contained" color="primary" onClick={finishWork}>
                    {t("HOME.BUTTON_SMS_CONFIRM")}
                </GreenButton>
            </Box>
            </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={DoClose} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FormDialog;
