import DEF from "../def";
const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.USER_UPDATE:
            //console.log('reducer,action.type:USER_UPDATE, action.value:',action.value);
            return action.value;
        default:
            return state;
    }
}