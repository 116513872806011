import React, { useState } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Container, Typography, Button, TextField, CssBaseline, Link, Grid } from "@material-ui/core";
import ToretaApi from "../ToretaApi";
import { useSelector } from "react-redux";
const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logo: {
        width: theme.spacing(15),
        marginBottom: theme.spacing(3),
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function Login() {
    const { t } = useTranslation();
    // let defaultAlertMessage = "";
    const currentLanguage: string = useSelector((state: any) => state.lang);
    const toretaApi = ToretaApi();
    const [inputs, setInputs] = useState({
        email: "",
        pass: "",
    });

    const classes = useStyles();
    const handleInput = (e: any) => {
        e.persist();
        setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }));
    };
    const doLogin = () => {
        toretaApi.UserLogin(inputs.email, inputs.pass,currentLanguage);
    };
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component="h1" variant="h5"></Typography>
                <form className={classes.form} noValidate>
                    <TextField variant="outlined" margin="normal" required fullWidth id="email" label={t("MANAGER.LOGIN.ACCOUNT")} name="email" autoComplete="email" autoFocus onChange={handleInput} />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="pass"
                        label={t("MANAGER.LOGIN.PASSWORD")}
                        type="password"
                        id="pass"
                        autoComplete="current-password"
                        onChange={handleInput}
                    />
                    <Grid container justify="flex-end">
                        <Link rel="noopener" href="/forgetPassword">
                            {t("MANAGER.LOGIN.FORGOT_PASSWORD")}
                        </Link>
                    </Grid>
                    <Button type="button" fullWidth variant="contained" color="primary" className={classes.submit} onClick={doLogin}>
                        {t("MANAGER.LOGIN.SIGNIN")}
                    </Button>
                </form>
            </div>
        </Container>
    );
}
export default Login;
