import React from "react";
import Grid from "@material-ui/core/Grid";
import Rtable from "./RTable";
import { PaymentProvider } from './CourseContext';
function Reservations() {
    
    return (
        <>
        <PaymentProvider>
            <Grid item xs={12} container spacing={2} alignItems="center">
                <Rtable />
            </Grid>
        </PaymentProvider>
        </>
    );
}
export default Reservations;
