import { store, DEF } from "../";
import { ShopDetail, ShopInfo } from "../../common/interface";

export const loadingControl = (show = true) => {
    // console.log('loadingControl',show);
    const dispatch = store.dispatch;
    if (show) {
        dispatch({ type: DEF.LOADING_SHOW });
    } else {
        dispatch({ type: DEF.LOADING_DISMISS });
    }
};
export const updateShopDetail = (value: ShopDetail) => {
    const dispatch = store.dispatch;
    // console.log('loadingControl',show);
    dispatch({ type: DEF.SHOP_UPDATE, value });
};
export const updateShopOther = (value: ShopInfo) => {
    const dispatch = store.dispatch;
    // console.log('loadingControl',show);
    dispatch({ type: DEF.SHOPINFO_UPDATE, value });
};
export const updteLang = (value: string) => {
    const dispatch = store.dispatch;
    // console.log('loadingControl',show);
    localStorage.setItem("lang", value);
    dispatch({ type: DEF.LANG_CHANGE, value });
};
export const updateUser = (value: object) => {
    const dispatch = store.dispatch;
    // console.log('loadingControl',show);
    dispatch({ type: DEF.USER_UPDATE, value });
};
export const toggleSideMenu = () => {
    const dispatch = store.dispatch;
    // console.log('loadingControl',show);
    dispatch({ type: DEF.TOGGLE_SIDEMENU });
};
// eslint-disable-next-line
export default { loadingControl, updateShopDetail, updateShopOther, updteLang, updateUser, toggleSideMenu };
