
import loading from "./loading";
import shop from "./shop"
import shopinfo from "./shopinfo"
import lang from "./lang"
import user from "./user"
import sidemenu from "./sidemenu"
import { combineReducers } from "redux";

export default combineReducers({
    user,
    loading,
    shop,
    shopinfo,
    lang,
    sidemenu
});
