import React from "react";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles, Theme } from "@material-ui/core/styles";
// import { UseAppContext } from "../Context/App";
const useStyles = makeStyles((theme: Theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: "#fff",
    },
}));

export default function LoadingBackdrop({ ...props }) {
    const loading = useSelector((state: any) => state.loading.length > 0);
    const classes = useStyles();
    return (
        <Backdrop {...props} open={loading} className={classes.backdrop}>
            <ReactLoading type="spinningBubbles" color="white" />
        </Backdrop>
    );
}
