import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
// import { /*NavLink,*/ useParams } from "react-router-dom";
import Layout, { ContainerView } from "./Layout";
// import { AppContext, AppContextProps } from "./Context/App"; 

import "./common/i18n";
//import SideMenu from "./Pages/SideMenu";
// import Test from "./Pages/Test";
// import Error from "./Pages/Error";
import LoginPage from "./Pages/Login";
import ConfirmOTP from "./Pages/ConfirmOTP";
import ResetPasswordPage from "./Pages/ResetPassword";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
import Reservations from "./Pages/Reservations";
import User from "./Pages/User";
import Payment from "./Pages/Payment";
import DebitRule from "./Pages/Debitrule";
import ToretaApi from "./ToretaApi";
import Company from "./Pages/Company";
import { ManageUser } from "./common/interface";
import { updateUser } from "./Actions/Actions";
function App() {
    const toretaApi = ToretaApi();
    const [loading, setLoading] = React.useState(true);
    const user: ManageUser = useSelector((state: any) => state.user);
    useEffect(() => {
        toretaApi.checkUserLogin().then(() => {
            setLoading(false);
        });
        if (window.performance && window.performance.navigation.type === 1) {
            user.isOTPExpired = true;
            updateUser(user);
        }
        // eslint-disable-next-line
    }, []);
    if (loading) {
        return <Layout></Layout>;
    }
    if(!user.id && window.location.pathname === "/resetpassword") {
        return (
            <Layout>
                <ResetPasswordPage />
            </Layout>
        ); 
    }
    if(!user.id && window.location.pathname === "/forgetPassword") {
        return (
            <Layout>
                <ForgetPasswordPage />
            </Layout>
        );
    }
    //console.log('user id'+user.id);
    if (!user.id) {       
        return (
            <Layout>
                <LoginPage />
            </Layout>
        );
    }

    if(user.isConfirmOTP && !user.isOTPExpired){
        return (
            <Layout>
                <ConfirmOTP />
            </Layout>
        );
    }
    // console.log('app render.', user, loading, toretaApi)
    return (
        <Router>
            <Layout>
                <ContainerView>
                    <Switch>
                        <Route exact path="/company" component={() => (user.admin ? <Company /> : <Reservations />)} />
                        <Route exact path="/user" component={() => (user.admin || user.companyOwner || user.restaurantOwner ? <User /> : <Reservations />)}></Route>
                        <Route exact path="/payment" component={() => <Payment />}></Route>
                        <Route exact path="/debitrule" component={() => <DebitRule />}></Route>
                        <Route exact path="/resetpassword" component={() => <ResetPasswordPage />}></Route>
                        <Route exact path="/forgetPassword" component={() => <ForgetPasswordPage />}></Route>
                        <Route component={() => <Reservations />}></Route>
                    </Switch>
                </ContainerView>
            </Layout>
        </Router>
    );
}

export default App;
