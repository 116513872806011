import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";

import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { Button, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import * as Moment from "moment";
import { extendMoment } from "moment-range";
import ToretaApi from "../../ToretaApi";
import Dialog from "./DateDialog";
import RegularDialog from "./RegularSettingDialog";
import { DateDebitruleUpdateBody, MonthDebitruleList, DateWithRule } from "../../common/interface";
const moment = extendMoment(Moment);

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    container: {
        maxHeight: 400,
    },
});
type Props = {
    companyId: number;
    restaurantId: number;
};
interface DialogStatus {
    open: boolean;
    data: DateDebitruleUpdateBody | null;
}
const StickyTable: React.FunctionComponent<Props> = ({ companyId, restaurantId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [pageData, setPageData] = React.useState<MonthDebitruleList | null>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage] = React.useState(10);

    const [dialogStatus, setDialogStatus] = React.useState<DialogStatus>({ open: false, data: null });
    const [regularDialogStatus, setRegularDialogStatus] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState<any>(moment());

    const handleDateChange = (date: any, value?: string | null | undefined) => {
        setSelectedDate(date);
        // setFormatdate(value);
    };
    const dialogClose = () => {
        setDialogStatus({ open: false, data: null });
    };
    const dialogOpen = (data: DateWithRule) => {
        const usedData: DateDebitruleUpdateBody = {
            ...data,
            oldRuleId: data.ruleId || 0,
        };
        setDialogStatus({
            open: true,
            data: usedData,
        });
    };
    const regularDialogClose = () => {
        setRegularDialogStatus(false);
    };
    const regularDialogOpen = () => {
        setRegularDialogStatus(true);
    };

    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const clickButton = () => {
        if (page === 0) {
            search();
        } else {
            setPage(0);
        }
    };
    const search = () => {
        toretaApi.getMonthDebitRule(restaurantId, selectedDate.format("YYYY-MM")).then(setPageData);
    };

    useEffect(() => {
        if (selectedDate?._isValid) {
            search();
        } else {
            setPageData(null);
        }
        // eslint-disable-next-line
    }, [restaurantId, page, rowsPerPage]);
    const monthData = React.useMemo(() => {
        if (!pageData) {
            return [];
        } else {
            const month = selectedDate;
            const range = moment().range(moment(month).startOf("month"), moment(month).endOf("month"));
            const days = range.by("days");
            const alldates = Array.from(days).map((date) => date.format("YYYY-MM-DD"));
            let type1Id: number = 0;
            // console.log("test", type1Id);
            const type1option = pageData.rules?.find((a) => a.type === 1);
            if (type1option) {
                type1Id = type1option.id;
            }
            return alldates.map((date) => {
                const target = pageData.dates.find((a) => a.date === date);
                return {
                    date,
                    ruleId: target ? target.ruleId : type1Id,
                };
            });
        }
        // eslint-disable-next-line
    }, [pageData]);
    if (!restaurantId) {
        return <></>;
    }
    // console.log(pageData);
    return (
        <>
            <Dialog restaurantId={restaurantId} open={dialogStatus.open} rules={pageData ? pageData.rules : null} data={dialogStatus.data} close={dialogClose} search={search} />
            <RegularDialog restaurantId={restaurantId} open={regularDialogStatus} rules={pageData ? pageData.rules : null} close={regularDialogClose} success={search} />
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <KeyboardDatePicker
                        variant="inline"
                        format="YYYY-MM"
                        margin="normal"
                        fullWidth
                        disableToolbar
                        autoOk={true}
                        views={["year", "month"]}
                        label={t("MANAGER.DEBITRULE.DATE")}
                        value={selectedDate}
                        onChange={handleDateChange}
                        KeyboardButtonProps={{
                            "aria-label": "change date",
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button variant="contained" color="primary" style={{ marginTop: "16px" }} disabled={!restaurantId} onClick={clickButton}>
                        {t("MANAGER.ACTION.SEARCH")}
                    </Button>
                    <Button variant="contained" color="primary" style={{ marginTop: "16px" }} onClick={regularDialogOpen}>
                        {t("MANAGER.ACTION.QUICK_SETTING")}
                    </Button>
                </Grid>
            </Grid>
            {monthData && monthData.length > 0 ? (
                <Grid item xs={12}>
                    <Divider />
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("MANAGER.DEBITRULE.DATE")}</TableCell>
                                        <TableCell>{t("MANAGER.DEBITRULE.NAME")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {monthData.map((row) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.date}>
                                                <TableCell>{row.date}</TableCell>
                                                <TableCell>{pageData && pageData.rules.find((a) => a.id === row.ruleId)?.name}</TableCell>
                                                {isManageLevel ? (
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            onClick={() => {
                                                                dialogOpen(row);
                                                            }}
                                                        >
                                                            {t("MANAGER.ACTION.EDIT")}
                                                        </Button>
                                                    </TableCell>
                                                ) : null}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default StickyTable;
