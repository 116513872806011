export interface Lang {
    name: string;
    value: string;
}
export const LangList: Lang[] = [
  {
    name: "English",
    value: "en",
  },
  {
    name: "日本語",
    value: "ja",
  },
  {
    name: "繁體中文",
    value: "zh-TW",
  },
  {
    name: "Tiếng Việt",
    value: "vi",
  },
//   {
//     name: "Thai",
//     value: "th",
//   },
];
const PickLang = (code: string): string => {
    const first = LangList.find((a) => a.value === code);
    if (first) {
        return first.value;
    }
    const code2 = code.substring(0, 2);
    const second = LangList.find((a) => a.value.indexOf(code2) === 0);
    if (second) {
        return second.value;
    }
    return LangList[0].value;
};
const localLang: string = PickLang(localStorage.getItem("lang") || navigator.language);
export { PickLang, localLang };
export default LangList;
