import DatePicker from "./Datepicker";
import { Theme, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import { TabPanel, TabPanelProps } from "./TabPanel";
import { TimeSpanPicker, TimeSpanPickerProps } from "./TimeSpanPicker";
import ImageUploadButton from "./ImageUploadButton";
// import Checkbox, { CheckboxProps } from "@material-ui/core/Checkbox";
// import CheckBoxIcon from "@material-ui/icons/CheckBox";
const GreenButton = withStyles((theme: Theme) => ({
    root: {
        color: "white",
        minWidth: "300px",
        paddingTop: "15px",
        paddingBottom: "15px",
        fontSize: "16px",
        backgroundColor: green[500],
        "&:hover": {
            color: "white",
            backgroundColor: green[700],
        },
        "&:disable": {
            color: "white",
            backgroundColor: green[700],
        },        
    },
}))(Button);
export type { TabPanelProps, TimeSpanPickerProps };
export { GreenButton, DatePicker, TabPanel, ImageUploadButton, TimeSpanPicker };
