import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { Button, TextField, Divider } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import ToretaApi from "../../ToretaApi";
import Dialog from "./Dialog";
import DialogReport from "./DialogReport";
import { ManageUser, ListWithCount, ReservationFilter, ReserveBody } from "../../common/interface";
import moment from "moment";
import { Phone } from "@material-ui/icons";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        container: {
            maxHeight: window.innerHeight - 360,
            [theme.breakpoints.down("md")]: {
                maxHeight: window.innerHeight - 360,
            },
        },
    })
);
type Props = {
    companyId: number;
    restaurantId: number;
};
function ValidateEmail(mail: string) {
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
        return true;
    }

    return false;
}
const StickyTable: React.FunctionComponent<Props> = ({ companyId, restaurantId }) => {
    const JAPAN_COMPANY_CODE="jp";
    const classes = useStyles();
    const { t } = useTranslation();
    const user: ManageUser = useSelector((state: any) => state.user);
    const [pageData, setPageData] = React.useState<ListWithCount | any>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [filter, setFilter] = React.useState<ReservationFilter>({
        reserveNo: "",
        phone: "",
        page: 0,
        perPage: 0,
    });
    const companyCountry: any = useSelector((state: any) => state.companyCountry);
    const paymentService = t("MANAGER.PAYMENT_SERVICE", { returnObjects: true });
    const reserveStatus = t("MANAGER.RESERVE_STATUS", { returnObjects: true });

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const toretaApi = ToretaApi();
    const clickButton = () => {
        if (page === 0) {
            search();
        } else {
            setPage(0);
        }
    };
    const search = () => {
        const sendFilter: ReservationFilter = {
            ...filter,
            page: page,
            perPage: rowsPerPage,
        };
        toretaApi.getReservationList(restaurantId, sendFilter).then(setPageData);
    };
    //代客預約
    const clickAddButton = () => {
        const sendFilter = {
            companyId: companyId,
            page: 0,
            perPage: rowsPerPage,
        };
        if((user.admin? user.select_country==JAPAN_COMPANY_CODE: user.company_country==JAPAN_COMPANY_CODE))return;
        //取得餐廳參數以啟動預約程序
        toretaApi.getRestaurantStripeList(sendFilter).then( (data)=> {
            //console.log("getRestaurantStripeList:",data);
            data?.rows?.forEach(row => {
                if(row.id === restaurantId){
                    if(row.enabled===true){
                        toretaApi.GetShopDetail(row.publicKey).then( (shopdata)=> {
                            //console.log("clickAddButton,GetShopDetail:",shopdata);
                            if(shopdata && shopdata.email && shopdata.email !== "" && shopdata.email !== undefined  ){
                                //console.log("shopdata.email:",shopdata.email);
                                dialogOpen(shopdata);
                            }else{
                                Swal.fire({
                                    icon: "error",
                                    title: t("MANAGER.ERRORMESSAGE.SHOP-001"),
                                });
                            }
                        });
                        
                    }else{

                        Swal.fire({
                            icon: "error",
                            title: row.name + ' ' + t("MANAGER.FIELD.STATUS") + ':' + t("MANAGER.STATUS.DISABLED"),
                        });
                        //console.log(row.name + " is disabled.");
                    }
                }
            });       
        });
        
    };
    const [selectedJapanCountry,SetJapanCountry] = React.useState<any>(false);
    const [dialogStatus, setDialogStatus] = React.useState<any>({ open: false , data: null });
    const [reportStatus, setReportStatus] = React.useState<any>({ open: false, data: {restaurantId: restaurantId} });
    const dialogClose = () => {
        setDialogStatus({ open: false, data: null });
    };
    const dialogOpen = (data:any) => {
        setDialogStatus({ open: true , data: { ...data } });
    };
    const reportOpen = (data:any) => {
        setReportStatus({ open: true , data: { ...data }});
    };

    const reportClose = () => {
        setReportStatus({ open: false});
    };
    // console.log(t("MANAGER.CONFIRM.REFUND"), t("MANAGER.CONFIRM", { returnObjects: true }));
    const refund = (rId: number, defaultamount: string) => {
        Swal.fire({
            title: t("MANAGER.CONFIRM.REFUND"),
            showDenyButton: true,
            icon: "warning",
            confirmButtonText: t("MANAGER.STATUS.YES"),
            denyButtonText: t("MANAGER.STATUS.NO"),
            input: "text",
            inputLabel: t("MANAGER.CONFIRM.REFUNDAMOUNT"),
            inputValue: defaultamount,
        }).then((result) => {
            // console.log(result);

            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                if (isNaN(parseFloat(result.value))) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.CONFIRM.REFUNDAMOUNT") + " Error!",
                    });
                    return;
                }
                if (parseFloat(result.value) > parseFloat(defaultamount)) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.CONFIRM.REFUNDAMOUNT") + " Error!",
                    });
                    return;
                }
                toretaApi.refund(companyId, rId, parseFloat(result.value)).then(() => {
                    search();
                });
            } else if (result.isDenied) {
                // Swal.fire("Changes are not saved", "", "info");
            }
        });
    };
    const notify = (rId: number, email: string) => {
        //當預約為代客輸入時，會額外發出簡訊
        Swal.fire({
            title: t("MANAGER.CONFIRM.FINE_NOTIFY"),
            showDenyButton: true,
            icon: "warning",
            confirmButtonText: t("MANAGER.STATUS.YES"),
            denyButtonText: t("MANAGER.STATUS.NO"),
            input: "text",
            inputLabel: t("MANAGER.FIELD.CUSTOMER_EMAIL"),
            inputValue: email,
        }).then((result) => {
            if (result.isConfirmed) {
                if (ValidateEmail(result.value)) {
                    toretaApi.fine_notify(companyId, rId, result.value);
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.FIELD.EMAIL") + " Error!",
                    });
                    return;
                }
            }
        });
    };
    const fine = (rId: number, defaultamount: string) => {
        //當預約為代客輸入時，會額外發出簡訊
        Swal.fire({
            title: t("MANAGER.CONFIRM.FINE"),
            showDenyButton: true,
            icon: "warning",
            confirmButtonText: t("MANAGER.STATUS.YES"),
            denyButtonText: t("MANAGER.STATUS.NO"),
            input: "text",
            inputLabel: t("MANAGER.CONFIRM.FINEAMOUNT"),
            inputValue: defaultamount,
        }).then((result) => {
            if (result.isConfirmed) {
                if (isNaN(parseFloat(result.value))) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.CONFIRM.FINEAMOUNT") + " Error!",
                    });
                    return;
                }
                if (parseFloat(result.value) > parseFloat(defaultamount)) {
                    Swal.fire({
                        icon: "error",
                        title: t("MANAGER.CONFIRM.FINEAMOUNT") + " Error!",
                    });
                    return;
                }
                toretaApi.fine(companyId, rId, parseFloat(result.value)).then(() => {
                    search();
                });
            }
        });
    };
    const fineDisabled = (row: ReserveBody) => {
        if (row.paymentService === 0) {
            return true;
        }
        if (row.status !== 0) {
            return true;
        }
        return false;
    };
    const refundDisabled = (row: ReserveBody) => {
        if (row.paymentService === 0) {
            return true; //未刷卡則無退款
        }
        //退款邏輯 1:已收訂金 3:部分返還
        if (row.status === 0 || row.status === 2 || row.status === 4|| row.status === 5|| row.status === 6) {
            return true;
        }
        return false;
    };
    useEffect(() => {
        console.log(user);
        SetJapanCountry((user.admin? user.select_country==JAPAN_COMPANY_CODE: user.company_country==JAPAN_COMPANY_CODE));
        search();
        // eslint-disable-next-line
    }, [companyId, restaurantId, page, rowsPerPage]);

    if (!restaurantId) {
        return <></>;
    }
    const IsManager = () => {
        if (user.admin) {
            return true;
        } else if (user.restaurantOwner) {
            return true;
        } else if (user.companyOwner) {
            return true;
        }
    };
    const isManger = IsManager();
    // console.log(pageData);
    const MaskedName=(last_name:string, first_name:string  ,mask = '***' )=>{
        const fullName = `${last_name} ${first_name}`;
        return  fullName.length === 1 ? mask : fullName.charAt(0) + mask;
    }
    const MaskedPhone=(phone:string,mask = '****' )=>{
        if(!phone || phone.length <4)  return mask;
        const lastFourDigits = phone.slice(-4);
        const maskedNumber = '****' + lastFourDigits;
        return maskedNumber;
    }
    return (
        <>
            <Dialog open={dialogStatus.open} data={dialogStatus.data} close={dialogClose}/>
            <DialogReport open={reportStatus.open} data={reportStatus.data} close={reportClose} search={search} />
            <Grid item xs={3} lg={3}>
                <TextField
                    variant="outlined"
                    value={filter.reserveNo}
                    fullWidth
                    label={t("FINISH.RESERVE_NO")}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            reserveNo: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={4} lg={3}>
                <TextField
                    variant="outlined"
                    value={filter.phone}
                    fullWidth
                    label={t("MOBILE")}
                    onChange={(event) => {
                        setFilter({
                            ...filter,
                            phone: event.target.value,
                        });
                    }}
                />
            </Grid>
            <Grid item xs={2} lg={2}>
                <Button variant="contained" color="primary" disabled={!restaurantId} onClick={clickButton}>
                    {t("MANAGER.ACTION.SEARCH")}
                </Button>
            </Grid>
            {selectedJapanCountry==false? (
            <Grid item xs={2} lg={2}>
                <Button variant="contained" color="primary" disabled={!restaurantId} onClick={clickAddButton}>
                    {t("MANAGER.ACTION.ADD_RS")}
                </Button>
            </Grid>
            ):(<></>)}
            <Grid item xs={2}>

                <Button variant="contained" color="secondary" onClick={
                    () =>
                    reportOpen(reportStatus.data)
                }>
                    {t("MANAGER.MENU.RESERVATION_REPORT")}
                </Button>
            </Grid>
            {pageData && pageData.rows ? (
                <Grid item xs={12}>
                    <Divider />
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell>{t("FINISH.RESERVE_NO")}</TableCell>
                                        <TableCell>{t("MANAGER.RESERVE.TOA")}</TableCell>
                                        {/* <TableCell>{t("NAME")}</TableCell>*/}
                                        <TableCell>{t("PHONE")}</TableCell>
                                        <TableCell>{t("MANAGER.RESERVE.STATUS")}</TableCell>
                                        <TableCell>{t("MANAGER.RESERVE.PAYMENT")}</TableCell>
                                        <TableCell>{t("MANAGER.RESERVE.FEE")}</TableCell>
                                        <TableCell>{t("MANAGER.RESERVE.CAPTURE")}</TableCell>
                                        {/* <TableCell>{t("MANAGER.RESERVE.REFUNDFEE")}</TableCell> */}
                                        {/* removed column at 2022-02-10 */}
                                        {isManger ? <TableCell>{t("MANAGER.RESERVE.ACTIONS")}</TableCell> : <></>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData.rows.map((row: ReserveBody) => {
                                        let row_style = {};
                                        let toa = new Date(`${row.toretaInfo.target_date}T${toretaApi.transformTimeNumber24h(row.toretaInfo.start_time)}`);
                                        if(toa && toa.getTime() < Date.now() ){
                                            row_style = { backgroundColor: "#afafb0" };
                                        }else{
                                            row_style = {};
                                        }
                                        return (
                                            
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id} 
                                            style={row_style} >
                                                <TableCell>#{row.reserveNo}</TableCell>
                                                <TableCell>{row.targetAt? 
                                                    moment(row.targetAt).format("YYYY/MM/DD HH:mm"):
                                                    moment(`${row.toretaInfo.target_date} ${toretaApi.transformTimeNumber24h(row.toretaInfo.start_time)}`).format("YYYY/MM/DD HH:mm")}</TableCell>
                                                {/*<TableCell>
                                                    {row.toretaInfo ? `${row.toretaInfo.title ? `${row.toretaInfo.title}.` : ""} ${MaskedName(row.toretaInfo.last_name,row.toretaInfo.first_name)}` : ""}
                                                </TableCell> */}
                                                <TableCell>{MaskedPhone( row.toretaInfo.phone)}</TableCell>
                                                <TableCell>{reserveStatus[row.status]}</TableCell>
                                                <TableCell>{paymentService[row.paymentService]}</TableCell>
                                                <TableCell>{row.fee}</TableCell>
                                                <TableCell>{row.capture}</TableCell>
                                                {/* <TableCell>{row.refundfee}</TableCell> */}
                                                {/* removed column at 2022-02-10 */}
                                                {isManger ? (
                                                    <TableCell>
                                                        {/* <Button color="primary" variant="contained">
                                                        {t("MANAGER.RESERVE.DETAIL")}
                                                    </Button> */}
                                                        {row.status === 4 ? (
                                                            <Button color="secondary" variant="contained" onClick={() => notify(row.id, row.toretaInfo.email)}>
                                                                {t("MANAGER.RESERVE.NOTIFY")}
                                                            </Button>
                                                        ) : (
                                                            <Button color="secondary" variant="contained" onClick={() => fine(row.id, `${row.fee}`)} disabled={fineDisabled(row)}>
                                                                {t("MANAGER.RESERVE.FINE")}
                                                            </Button>
                                                        )}

                                                        <Button
                                                            color="secondary"
                                                            variant="contained"
                                                            onClick={() => refund(row.id, (row.fee - row.refundfee).toFixed(2))}
                                                            disabled={refundDisabled(row)}
                                                        >
                                                            {t("MANAGER.RESERVE.REFUND")}
                                                        </Button>
                                                    </TableCell>
                                                ) : (
                                                    <></>
                                                )}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={pageData.count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => {
                                return t("MANAGER.TABLE.CURRENT", {
                                    from,
                                    to,
                                    count,
                                });
                            }}
                            labelRowsPerPage={t("MANAGER.TABLE.PERPAGE")}
                            backIconButtonText={t("MANAGER.TABLE.PER_PAGE")}
                            nextIconButtonText={t("MANAGER.TABLE.NEXT_PAGE")}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default StickyTable;
