import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import AutocompleteField from "../../Components/AutocompleteField";
import { TabPanel } from "../../Components";
import Rtable from "./RTable";
import Dtable from "./DTable";
import ToretaApi from "../../ToretaApi";
import { isBuffer } from "util";

function Reservations() {
    const { t } = useTranslation();
    const user: any = useSelector((state: any) => state.user);
    const toretaApi = ToretaApi();
    useEffect(() => {
        toretaApi.getCompanyList().then((data) => {
            setCompanyList(data);
        });
        // eslint-disable-next-line
    }, []);
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    // const handleChangeIndex = (index: number) => {
    //     setValue(index);
    // };
    
    const [company, setCompany] = React.useState<number | null>(null);
    const [restaurant, setRestaurant] = React.useState<number | null>(null);
    const [companyList, setCompanyList] = React.useState<any>(null);
    const [restaurantList, setRestaurantList] = React.useState<any>(null);
    useEffect(() => {
        if (!user.admin && user.companyId) {
            setCompany(user.companyId);
        }
    }, [user]);
    useEffect(() => {
        toretaApi.getRestaurantList(company).then(setRestaurantList);
        // eslint-disable-next-line
    }, [company]);
    useEffect(() => {
        if (restaurantList?.length === 1) {
            setRestaurant(restaurantList[0].value);
        }
    }, [restaurantList]);
    // console.log("index", typeof company, typeof restaurant);
    // if (!companyList) {
    //     return <></>;
    // }
    // console.log(company, restaurant);
    return (
        <>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={6} lg={3}>
                    <AutocompleteField
                        label={t("MANAGER.FIELD.COMPANY")}
                        optionList={companyList}
                        value={company}
                        onChange={(value: any) => {
                            setCompany(value);
                            setRestaurant(null);
                        }}
                    />
                </Grid>
                <Grid item xs={6} lg={3}>
                    <AutocompleteField label={t("MANAGER.FIELD.RESTAURANT")} optionList={restaurantList} value={restaurant} onChange={setRestaurant} disabled={!company} />
                </Grid>
                {restaurant && restaurant > 0 ? (
                    <Grid item xs={12} container spacing={2} alignItems="center">
                        <AppBar position="static" color="default">
                            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary" variant="fullWidth" aria-label="full width tabs example">
                                <Tab label={t("MANAGER.DEBITRULE.NAME")} id={`full-width-tab-0`} aria-controls={`full-width-tabpanel-0`} />
                                <Tab label={t("MANAGER.DEBITRULE.DATE")} id={`full-width-tab-1`} aria-controls={`full-width-tabpanel-0`} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                            <Rtable restaurantId={restaurant} companyId={company as number} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                            <Dtable restaurantId={restaurant} companyId={company as number} />
                        </TabPanel>
                    </Grid>
                ) : (
                    <></>
                )}
            </Grid>
        </>
    );
}
export default Reservations;
