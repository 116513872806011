import * as React from 'react';
import { readFile } from '../ImageResize/helpers/cropImage';
import { useImageCropContext } from '../ImageResize/providers/ImageCropProvider';
import Button from "@material-ui/core/Button";
import Cropper from '../ImageResize/cropper/Cropper';
import { RotationSlider, ZoomSlider } from '../ImageResize/cropper/Sliders';
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useTranslation } from "react-i18next";

const ImageCropModalContent = ({onImageUrlChange, handleDone, handleClose }) => {
  const [editable, setEditable] = React.useState(false);
  const { setImage } = useImageCropContext();
  const { t } = useTranslation();
  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
    onImageUrlChange(imageDataUrl);
    setEditable(true);
  };

  const onDone=()=>{
    handleDone();
    setEditable(false);
  }
  const onClose=()=>{
    setEditable(false);
    handleClose();
  }
  return (
    <div className="text-center relative ce">
      <h5 className="text-gray-800 mb-4 hidden">Edit profile picture</h5>
      <div className="border border-dashed border-gray-200 p-6 rounded-lg">
        {editable?(
        <>
        <div className="flex justify-center">
          <div className="crop-container mb-4">
            <Cropper />
          </div>
        </div>
        <ZoomSlider className="mb-4"/>
        <RotationSlider className="mb-4"/>
        </>):<></>}
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="avatarInput"
          accept="image/*"
        />
          <label htmlFor="upload-photo" display={!editable}>
                <input style={{ display: "none" }} id="upload-photo" name="upload-photo" type="file" accept="image/*" onChange={handleFileChange} />
                <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended">
                    <AddIcon /> {t("MANAGER.COURSE.ACTION.ADD_COURSE_PIC")}
                </Fab>
            </label>
            <br />  <br />
        {editable?(
          <>
        <div className="flex gap-2">
          <Button variant="secondary" onClick={onClose} color="secondary" >
            {t("MANAGER.COURSE.ACTION.CANCEL")}
          </Button>

          <Button variant="primary" className="w-full" onClick={onDone} color="primary">
          {t("MANAGER.COURSE.ACTION.SAVE")}
          </Button>

        </div>
        </>):<></>}
      </div>
    </div>
  );
};

export default ImageCropModalContent;