import React, { useEffect } from "react";
// import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";
import {  Box } from "@material-ui/core";
import ToretaApi from "../../ToretaApi";
import AutocompleteField from "../../Components/AutocompleteField";
import currency from "../../common/Const/currency.json";
import currencyMap from "../../common/Const/currencyMap.json";
import { CompanyBody } from "../../common/interface";
import { ImageUploadButton } from "../../Components";
type Props = {
    open: boolean;
    data: CompanyBody | null;
    close: () => void;
    search: () => void;
};
const countries = [
    { value: "sg", label: "Singapore / SG" },
    { value: "tw", label: "Taiwan / TW" },
    { value: "my", label: "Malaysia / MY" },
    { value: "th", label: "Thailand / TH" },
    { value: "jp", label: "Japan / JP" },
];
const defaultValue: CompanyBody = {
    name: "",
    currency: null,
    country: null,
    picture: null,
    publicApiToken: null,
    file: null,
};
const FormDialog: React.FunctionComponent<Props> = ({ open, data, close, search }) => {
    const { t } = useTranslation();
    const currencyList = React.useMemo(() => {
        const useCurrency: any = currency;
        const result = [];
        for (let key in useCurrency) {
            const obj = useCurrency[key];
            result.push({
                value: obj.code,
                label: `${obj.symbol} / ${obj.name} / ${obj.code}`,
            });
        }
        return result;

        // eslint-disable-next-line
    }, [currency]);

    const currencyMapList = () => {
        const useCurrency: any =  currency;
        const useCurrencyMap: any = currencyMap;
        const result = [];
        for (let key in useCurrencyMap) {
            const currencyCodes = useCurrencyMap[key];
            for (let index = 0; index < currencyCodes.length; index++) {
                const currencyCode = currencyCodes[index];
                const currencyInfo=useCurrency[currencyCode];
                result.push({
                    countryCode:key,
                    value: currencyCode,
                    label: `${currencyInfo.symbol} / ${currencyInfo.name} / ${currencyInfo.code}`,
                });

            } 
        }
        
        var lst= result.filter((x)=>{return x.countryCode==form.country;});
        if(lst.length>0){
            var currVal= lst.filter((x)=>{return x.value==form.currency;});
            if(currVal.length>0){
                form.currency=currVal[0].value;
            }else{
                form.currency=lst[0].value;
            }
        }
        return lst;
        // eslint-disable-next-line
    };
    const toretaApi = ToretaApi();

    const [form, setForm] = React.useState<CompanyBody>(defaultValue);

    useEffect(() => {
        // console.log('test data',data)
        if (data !== null) {
            setForm({ ...defaultValue, ...data });
        }
    }, [data]);

    const updateData = () => {
        const IsBlank = (value: string | null) => {
            return !value || value === undefined || value === "";
        };

        if (IsBlank(form.name)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.COMPANY-001"),
            });
            return;
        } else if (IsBlank(form.publicApiToken)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.COMPANY-002"),
            });
            return;
        } else if (IsBlank(form.currency)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-003"),
            });
            return;
        } else if (IsBlank(form.country)) {
            Swal.fire({
                icon: "error",
                title: t("MANAGER.ERRORMESSAGE.PAYMENTSETTING-004"),
            });
            return;
        } else {
            const successAction = () => {
                search();
                close();
            };
            toretaApi.updateCompany(form, successAction);
        }

        //
    };
    const fileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            setForm({ ...form, file: event.target.files[0] });
        }
    };
    // console.log("test", form, companyList);
    return (
        <div>
            <Dialog open={open} fullWidth={true} onClose={close} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{form.id ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.ADD")}</DialogTitle>
                <DialogContent>
                    <DialogContentText></DialogContentText>

                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.FIELD.COMPANY")}
                            type="text"
                            value={form.name}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, name: event.target.value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.FIELD.TORETA_API_TOKEN")}
                            type="text"
                            value={form.publicApiToken}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, publicApiToken: event.target.value });
                            }}
                        />
                    </Box>
                    
                    <Box p={1}>
                        <AutocompleteField
                            label={t("MANAGER.FIELD.COUNTRY")}
                            variant={"standard"}
                            optionList={countries}
                            value={form.country}
                            onChange={(value: any) => {
                                setForm({ ...form, country: value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <AutocompleteField
                            label={t("MANAGER.FIELD.CURRENCY")}
                            variant={"standard"}
                            optionList={currencyMapList()}
                            value={form.currency}
                            onChange={(value: any) => {
                                setForm({ ...form, currency: value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <TextField
                            label={t("MANAGER.FIELD.PIC")}
                            type="text"
                            value={form.picture}
                            fullWidth
                            onChange={(event) => {
                                setForm({ ...form, picture: event.target.value });
                            }}
                        />
                    </Box>
                    <Box p={1}>
                        <ImageUploadButton fileChange={fileChange} file={form.file} />                         
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={close} color="secondary">
                        {t("MANAGER.ACTION.CANCEL")}
                    </Button>
                    <Button onClick={updateData} color="primary">
                        {t("MANAGER.ACTION.OK")}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
export default FormDialog;
