import React, { useEffect } from "react";
import { Theme, makeStyles, createStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";
import { Button, Divider, Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ToretaApi from "../../ToretaApi";
import { PageFilter } from "../../common/interface";
import Dialog from "./Dialog";
import Swal from "sweetalert2";
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        container: {
            maxHeight: window.innerHeight - 390,
            [theme.breakpoints.down("md")]: {
                maxHeight: window.innerHeight - 390,
            },
        },
    })
);
type Props = {
    companyId: number;
    restaurantId: number;
};

const StickyTable: React.FunctionComponent<Props> = ({ companyId, restaurantId }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [pageData, setPageData] = React.useState<any>(null);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const [dialogStatus, setDialogStatus] = React.useState<any>({ open: false, data: null });

    const dialogClose = () => {
        setDialogStatus({ open: false, data: null });
    };
    const dialogOpen = (data: any | null) => {
        const copyData = {
            ...data,
        };
        if (data.dates) {
            copyData.dates = [...data.dates];
        }
        setDialogStatus({ open: true, data: copyData });
    };
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    const toretaApi = ToretaApi();
    const { isManageLevel } = toretaApi;
    const clickButton = () => {
        if (page === 0) {
            search();
        } else {
            setPage(0);
        }
    };
    const search = () => {
        const sendFilter: PageFilter = {
            page: page,
            perPage: rowsPerPage,
        };
        toretaApi.getDebitruleList(restaurantId, sendFilter).then(setPageData);
    };
    const deleteRule = (id: number, name: string) => {
        Swal.fire({
            title: t("MANAGER.DEBITRULE.DELETE_RULE_CONFIRM", { name }),
            // text: "You won't be able to revert this!",
            showDenyButton: true,
            icon: "warning",
            confirmButtonText: t("MANAGER.STATUS.YES"),
            denyButtonText: t("MANAGER.STATUS.NO"),
        }).then((result: any) => {
            if (result.isConfirmed) {
                toretaApi.deleteDebitrule(restaurantId, id, search).then(setPageData);
            }
        });
    };
    useEffect(() => {
        search();
        // eslint-disable-next-line
    }, [restaurantId, page, rowsPerPage]);
    if (!restaurantId) {
        return <></>;
    }
    // console.log(pageData);
    return (
        <>
            <Dialog open={dialogStatus.open} data={dialogStatus.data} close={dialogClose} search={search} />
            <Grid item xs={12}>
                <Box textAlign={"right"} p={1}>
                    <Button variant="contained" color="primary" disabled={!restaurantId} onClick={clickButton}>
                        {t("MANAGER.ACTION.REFRESH")}
                    </Button>
                    {isManageLevel ? (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                dialogOpen({ restaurantId, name: "", id: null, dates: [] });
                            }}
                        >
                            {t("MANAGER.ACTION.ADD")}
                        </Button>
                    ) : null}
                </Box>
            </Grid>
            {pageData && pageData.rows ? (
                <Grid item xs={12}>
                    <Divider />
                    <Paper className={classes.root}>
                        <TableContainer className={classes.container}>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t("MANAGER.DEBITRULE.NAME")}</TableCell>
                                        <TableCell>{t("MANAGER.DEBITRULE.RULE")}</TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pageData.rows.map((row: any) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>
                                                    {t(`MANAGER.DEBITRULE.RULE${row.type}`, row)}<br/>
                                                    {t(`MANAGER.DEBITRULE.DINNER_RULE${row.type}`, row)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => {
                                                            dialogOpen(row);
                                                        }}
                                                    >
                                                        {isManageLevel ? t("MANAGER.ACTION.EDIT") : t("MANAGER.ACTION.VIEW")}
                                                    </Button>
                                                    {isManageLevel ? (
                                                        <Button variant="contained" color="secondary" onClick={() => deleteRule(row.id, row.name)}>
                                                            {t("MANAGER.ACTION.DELETE")}
                                                        </Button>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={pageData.count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelDisplayedRows={({ from, to, count }) => {
                                return t("MANAGER.TABLE.CURRENT", {
                                    from,
                                    to,
                                    count,
                                });
                            }}
                            labelRowsPerPage={t("MANAGER.TABLE.PERPAGE")}
                            backIconButtonText={t("MANAGER.TABLE.PER_PAGE")}
                            nextIconButtonText={t("MANAGER.TABLE.NEXT_PAGE")}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </Paper>
                </Grid>
            ) : (
                <></>
            )}
        </>
    );
};
export default StickyTable;
